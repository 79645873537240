import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import i18next from 'i18next';

import { LANGUAGES } from '../config/constants';
import { REGISTER_NOTIFICATION_PUSH } from '../graphql/notifications/mutation';

// Cargar el plugin
dayjs.extend(isBetween);

export async function downloadDocumentClick(
  documentName: string,
  token: string,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
) {
  if (setLoading) setLoading(true);
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  fetch(`${process.env.REACT_APP_API_URI_FILE}?file=${documentName}`, {
    method: 'GET',
    headers,
  })
    .then((response) => {
      response.blob().then((blob) => {
        const newUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = newUrl;
        a.download = documentName || 'documento';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(newUrl);
        if (setLoading) setLoading(false);
      });
    })
    .catch((error) => {
      if (setLoading) setLoading(false);
      console.error('Error durante la descarga:', error);
    });
}

export async function fileLink(
  token: String,
  fileUrl: String,
  setFile: React.Dispatch<React.SetStateAction<string>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) {
  setIsLoading(true);

  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URI_FILE}?file=${fileUrl}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const blob = await res.blob();
    setFile(URL.createObjectURL(blob));
  } catch (error) {
    console.error('Error fetching file:', error);
  } finally {
    setIsLoading(false);
  }
}

export const urlRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export function getSegmentBeforeSecondUUID(url: string): string | null {
  const segments = url.split('/');
  let uuidCount = 0;

  for (let i = 0; i < segments.length; i += 1) {
    if (/^[0-9a-fA-F-]{36}$/.test(segments[i])) {
      uuidCount += 1;
      if (uuidCount === 2) {
        return segments[i - 1];
      }
      // If it's the first UUID and there is no second one, return the next segment
      if (
        uuidCount === 1 &&
        i + 1 < segments.length &&
        !segments.slice(i + 1).some((seg) => /^[0-9a-fA-F-]{36}$/.test(seg))
      ) {
        return segments[i + 1];
      }
    }
  }

  // if no second uuid, return whatever is after the first one
  for (let i = 0; i < segments.length; i += 1) {
    if (/^[0-9a-fA-F-]{36}$/.test(segments[i])) {
      return segments[i + 1];
    }
  }

  return null;
}

export const getNoteType = (
  location: string,
  isInfluencer: any,
  isBrand: any,
  isInfluentia: any,
  brandNotes?: Boolean,
) => {
  if (brandNotes) {
    return 'brand-influentia';
  }
  if (isInfluencer()) {
    return 'influencer-influentia';
  }
  if (isBrand()) {
    return 'brand-influentia';
  }
  if (isInfluentia()) {
    if (
      ['general', 'content', 'dates', 'settings-influencer'].includes(location)
    ) {
      return 'influencer-influentia';
    }
    return 'brand-influentia';
  }
  return '';
};

export const getUserLanguage = () =>
  window.navigator.language.split('-')[0] || 'es';
export const changeLanguage = (language: string, automatic: boolean) => {
  if (automatic) {
    if (LANGUAGES.includes(getUserLanguage())) {
      i18next.changeLanguage(getUserLanguage());
    }
  } else if (LANGUAGES.includes(language)) {
    i18next.changeLanguage(language);
  }
  // Implement the logic for manual language change
};

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function usePushSubscription() {
  const [registerNotification] = useMutation(REGISTER_NOTIFICATION_PUSH);
  const applicationServerKey = urlBase64ToUint8Array(
    process.env.REACT_APP_VAPID_PUBLIC_KEY || '',
  );

  const subscribeUserToPush = async () => {
    const registration = await navigator.serviceWorker.ready;
    try {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      });
      console.log('Push Subscription created');

      registerNotification({
        variables: {
          permissions: JSON.stringify(subscription),
        },
      });
    } catch (error) {
      registerNotification({
        variables: {
          permissions: null,
        },
      });
      console.error('Error during subscription:', error);
    }
  };

  return { subscribeUserToPush };
}

export function isValidUUIDv4(uuid: string) {
  const uuidv4Regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidv4Regex.test(uuid);
}

export function isInChristmasRange(date: Date) {
  const inputDate = dayjs(date);
  const year = inputDate.year();

  // Definir el rango de fechas
  const startOfRange = dayjs(`${year}-12-20`); // 20 de diciembre del año actual
  const endOfRange = dayjs(`${year + 1}-01-02`); // 2 de enero del año siguiente

  // Si estamos entre diciembre y enero, ajustar el año del rango
  if (inputDate.month() === 11) {
    // Si estamos en diciembre
    return inputDate.isBetween(startOfRange, endOfRange, null, '[]');
  }
  if (inputDate.month() === 0) {
    // Si estamos en enero
    const startOfRangePreviousYear = dayjs(`${year - 1}-12-20`);
    const endOfRangeThisYear = dayjs(`${year}-01-02`);
    return inputDate.isBetween(
      startOfRangePreviousYear,
      endOfRangeThisYear,
      null,
      '[]',
    );
  }

  // Fuera de los meses de diciembre y enero
  return false;
}

export function isInTheThreeKingsRange(date: Date) {
  const inputDate = dayjs(date);
  const year = inputDate.year();

  // Definir el rango de fechas
  const startOfRange = dayjs(`${year}-01-02`); // 2 de enero  del año actual
  const endOfRange = dayjs(`${year}-01-06`); // 2 de enero del año siguiente

  if (inputDate.month() === 0) {
    // Si estamos en enero
    return inputDate.isBetween(startOfRange, endOfRange, null, '[]');
  }

  // Fuera de los meses de diciembre y enero
  return false;
}
