import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CampaignIcon } from '../../assets';
import { PERMISSIONS } from '../../config/constants';
import { replaceIds, ROUTES } from '../../config/routes/config';
import { CREATE_CAMPAIGN } from '../../graphql/campaign/mutation';
import { usePermissions } from '../../hooks/usePermissions';
import type { ResCreateCampaignType } from '../../types';
import { Button } from '../common/Button';
import { DatePickerInput } from '../inputs/DatePicker';
import { TextInput } from '../inputs/TextInput';
import { UserDropdown } from '../inputs/UserDropdown';
import { UserDropdownList } from '../inputs/UserDropdownList';
import { PopupLayout } from './PopupLayout';

type TCreateCampaignForm = {
  name: string;
  client: string;
  influencers: Array<{ value: string; id: string }>;
  accountManagers: Array<{ value: string; id: string }>;
  month: string;
};

export const CreateCampaign = ({ refetch }: { refetch: any }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const { ...methods } = useForm<TCreateCampaignForm>();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const [createCampaign, { loading }] =
    useMutation<ResCreateCampaignType>(CREATE_CAMPAIGN);
  const { checkPermission } = usePermissions();
  const onSubmit = (data: TCreateCampaignForm) => {
    createCampaign({
      variables: {
        name: data.name,
        brand_id: data.client,
        influencers: data.influencers.map((i) => i.value) || [],
        accountManagers: data.accountManagers.map((i) => i.value) || [],
        month: data.month,
      },
      onCompleted: (res) => {
        navigate(
          replaceIds({
            url: ROUTES.SINGLE_CAMPAIGN_GENERAL,
            params: [
              {
                id: data.influencers[0].value || '',
              },
              { campaignId: res.createCampaign.data || '' },
            ],
          }),
        );
        reset();
        setOpen(false);
        refetch();
      },
      onError: () => {},
    });
  };

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style="light-blue"
        text={t('campaigns.createCampaign')}
        id="createCampaign"
        iconLeft={<CampaignIcon />}
        customClassnames={`${!checkPermission(PERMISSIONS.CREATE_CAMPAIGN) && 'hidden'}`}
      />
      {open && (
        <PopupLayout
          title={t('campaigns.createCampaignTitle')}
          closeModal={closeModal}
          icon={<CampaignIcon />}
        >
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-y-5 mt-5"
            >
              <div className="flex flex-row gap-x-8 w-full">
                <TextInput
                  register={register}
                  registerName="name"
                  type="text"
                  label={t('campaigns.createName')}
                  placeholder={t('campaigns.createNamePlaceholder')}
                  validation={{ required: t('campaigns.nameRequired') }}
                  errors={errors.name}
                />
                <DatePickerInput
                  name="month"
                  monthYearPicker
                  isClearable
                  label={t('campaigns.campaignMonth')}
                  control={control}
                  fullSpace
                  validation={{
                    required: true,
                  }}
                  errors={
                    errors.month
                      ? { message: t('campaigns.campaignMonthError') }
                      : ''
                  }
                />
              </div>
              <div className="flex flex-row gap-x-8 w-full">
                <div className="w-full">
                  <UserDropdown
                    control={control}
                    name="client"
                    hasAddButton
                    label={t('campaigns.createClient')}
                    placeholder={t('campaigns.createClientPlaceholder')}
                    validation={{ required: t('campaigns.clientRequired') }}
                    type="brand"
                    error={errors.client?.message}
                  />
                </div>
              </div>
              <UserDropdownList
                type="influentia"
                accessName="accountManagers"
                label={t('campaigns.createAccountManagers')}
                accessKey="value"
                addText={t('campaigns.createAccountManagersAdd')}
                placeholderSelects={t(
                  'campaigns.createAccountManagersPlaceholder',
                )}
                control={control}
                minLength={0}
              />
              <UserDropdownList
                type="influencer"
                accessName="influencers"
                label={t('campaigns.createInfluencers')}
                accessKey="value"
                addText={t('campaigns.createInfluencersAdd')}
                placeholderSelects={t('campaigns.createInfluencersPlaceholder')}
                control={control}
                error={
                  errors.influencers?.root
                    ? t('campaigns.addInfluencerError')
                    : ''
                }
              />
              <div className="flex justify-end">
                <div className="w-[150px]">
                  <Button
                    style="light-blue"
                    id="saveCampaign"
                    text={t('global.save')}
                    customClassnames="w-full justify-center"
                    type="submit"
                    loading={loading}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </PopupLayout>
      )}
    </>
  );
};
