import { gql } from '@apollo/client';

export const CREATE_BRAND = gql`
  mutation CreateBrand(
    $name: String!
    $cif: String!
    $address: String!
    $paymentTerms: String!
    $companyName: String!
  ) {
    createBrand(
      name: $name
      cif: $cif
      address: $address
      paymentTerms: $paymentTerms
      companyName: $companyName
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_BRAND = gql`
  mutation UpdateBrand(
    $brandId: String!
    $cif: String
    $address: String
    $paymentTerms: String
    $name: String
    $companyName: String
  ) {
    updateBrand(
      brandId: $brandId
      cif: $cif
      address: $address
      paymentTerms: $paymentTerms
      name: $name
      companyName: $companyName
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_BRAND_PROFILE_PICTURE = gql`
  mutation UpdateBrandProfilePicture($brandId: String!, $file: Upload!) {
    updateBrandProfilePicture(brandId: $brandId, file: $file) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_BRAND = gql`
  mutation DeleteBrand($brandId: String!) {
    deleteBrand(brandId: $brandId) {
      status
      code
      data
      error
    }
  }
`;
