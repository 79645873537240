import 'dayjs/locale/es';

import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { CampaignIcon, TrashIcon } from '../assets';
import { SingleCampaignInfo } from '../components/campaigns/Sidebar/SingleCampaignInfo';
import WarningNotifications from '../components/campaigns/Sidebar/WarningNotifications';
import { SingleCampaignTabs } from '../components/campaigns/SingleCampaignTabs';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { Button } from '../components/common/Button';
import { replaceIds, ROUTES } from '../config/routes/config';
import { Confirm } from '../context/confirm/confirm';
import { RefetchLateral } from '../context/refetchLateral/refetchProvider';
import { DELETE_CAMPAIGN } from '../graphql/campaign/mutation';
import {
  GET_SINGLE_CAMPAIGN_MAIN_INFO,
  SINGLE_CAMPAIGN,
} from '../graphql/campaign/query';
import { useNotification } from '../hooks/useNotification';
import { usePermissions } from '../hooks/usePermissions';
import { userAtom } from '../states/atoms';
import type {
  ResSingleCampaignType,
  SingleCampaignData,
  SingleCampaignMainInfo,
  StoppedNotifications,
} from '../types';
import {
  getNoteType,
  getSegmentBeforeSecondUUID,
  isValidUUIDv4,
} from '../utils/utils';

dayjs.extend(localizedFormat);
dayjs.locale('es');

function SingleCampaign() {
  const location = getSegmentBeforeSecondUUID(window.location.pathname) || '';
  const { isInfluencer, isInfluentia, isBrand } = usePermissions();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { campaignId, influencerId, id } = useParams();
  const user = useRecoilValue(userAtom);
  const [campaignIdState] = useState<string>(campaignId ?? '');
  const [notificationsStopped, setNotificationsStopped] = useState<
    StoppedNotifications[]
  >([]);
  const [influencerIdState, setInfluencerId] = useState<any>(influencerId);
  // IdState used for invoices tab where id can be both influencers and brands id
  const [idState, setId] = useState(id || influencerId);
  const [campaignInfo, setCampaignInfo] = useState<SingleCampaignMainInfo>();
  const [singleCampaignInfo, setSingleCampaignInfo] =
    useState<SingleCampaignData>();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (influencerId) {
      setInfluencerId(influencerId);
      setId(influencerId);
    }
  }, [influencerId]);
  useEffect(() => {
    if (id) setId(id);
    if (campaignInfo?.influencers.map((i) => i.id).includes(id as string))
      setInfluencerId(id);
  }, [id]);

  const { notifyWarning } = useNotification();
  useEffect(() => {
    if (!campaignIdState) {
      navigate(ROUTES.CAMPAIGNS);
      // TODO: SHOW MISSING CAMPAIGN ID NOTIFICATION
      notifyWarning(t('global.errorCampaign'));
    }
  }, []);

  const { refetch } = useQuery(SINGLE_CAMPAIGN, {
    skip:
      !influencerIdState ||
      idState !== influencerIdState ||
      (idState !== undefined && idState !== influencerIdState) ||
      id === 'brand' ||
      isBrand() === undefined ||
      idState === 'campaign',
    variables: {
      campaignId,
      influencerId: idState,
      type: getNoteType(location, isInfluencer, isBrand, isInfluentia),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data.singleCampaign.data) {
        navigate(ROUTES.CAMPAIGNS);
        notifyWarning(t('global.errorCampaign'));
        return;
      }
      setSingleCampaignInfo(data.singleCampaign.data);
    },
    onError: () => {
      // TODO: SHOW MISSING CAMPAIGN ID NOTIFICATION
      notifyWarning(t('global.errorCampaign'));
      navigate(ROUTES.CAMPAIGNS);
    },
  });

  const { refetch: refetchTabs } = useQuery<ResSingleCampaignType>(
    // const { loading } = useQuery<ResSingleCampaignType>(
    GET_SINGLE_CAMPAIGN_MAIN_INFO,
    {
      variables: {
        campaignId,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (!data.campaign.data) {
          navigate(ROUTES.CAMPAIGNS);
          notifyWarning(t('global.errorCampaign'));
          return;
        }
        setCampaignInfo(data.campaign.data);
        const uniqueStopped = data.campaign.data.stoppedNotifications.reduce(
          (acc, current) => {
            // Usa un objeto para rastrear los user_id únicos
            if (!acc.some((item) => item.user_id === current.user_id)) {
              acc.push({
                ...current,
              });
            }
            return acc;
          },
          [] as StoppedNotifications[],
        );
        setNotificationsStopped(uniqueStopped);
        if (isInfluencer()) setInfluencerId(user.id);
        else if (!influencerIdState) {
          if (
            data.campaign.data?.influencers
              .map((i) => i.id)
              .includes(id as string)
          ) {
            setInfluencerId(id);
          } else {
            setInfluencerId(data.campaign.data?.influencers[0]?.id || '');
          }
        }
      },
      onError: () => {
        // TODO: SHOW MISSING CAMPAIGN ID NOTIFICATION
        notifyWarning(t('global.errorCampaign'));

        navigate(ROUTES.CAMPAIGNS);
      },
    },
  );
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN);

  const deleteCampaignDB = () => {
    deleteCampaign({
      variables: {
        campaignId,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'DeleteCampaign',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        navigate(ROUTES.CAMPAIGNS);
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  useEffect(() => {
    if (
      idState !== influencerIdState &&
      idState !== 'campaign' &&
      idState !== 'influencers' &&
      campaignInfo?.influencers.map((i) => i.id).includes(id as string) &&
      influencerIdState
    )
      refetch();
  }, [location]);
  return (
    <RefetchLateral.Provider
      value={{
        refetchLateral: () => {
          if (idState) refetch();
        },
        refetchTabs,
        campaignId: campaignIdState,
        influencerId: influencerIdState || '',
        brandId: campaignInfo?.brand_id || '',
      }}
    >
      <div className="flex flex-row gap-x-5">
        <Confirm
          open={open}
          onConfirm={() => deleteCampaignDB()}
          setOpen={setOpen}
          type="delete"
          title={t('global.deleteTitle')}
          subtitle={t('global.deleteSubtitle')}
          confirmText={t('global.delete')}
          cancelText={t('global.cancel')}
        />
        <div
          className={`w-full flex flex-col gap-y-5 ${location !== 'general' && 'lg:max-w-[calc(100%-420px)]'}`}
        >
          <Breadcrumbs
            icon={<CampaignIcon />}
            breadcrumbs={[
              {
                text: t('campaigns.title'),
                link: ROUTES.CAMPAIGNS,
              },
              {
                text: campaignInfo?.name || '',
                link: replaceIds({
                  url: ROUTES.SINGLE_CAMPAIGN_GENERAL,
                  params: [
                    {
                      id: influencerIdState || 'campaign',
                    },
                    {
                      campaignId: campaignIdState!,
                    },
                  ],
                }),
              },
            ]}
          />
          <div>
            <SingleCampaignTabs
              campaignId={campaignIdState!}
              idState={idState!}
              influencerId={influencerIdState || ''}
              influencers={campaignInfo?.influencers || []}
              brandName={campaignInfo?.brand_name!}
              brandProfilePicture={campaignInfo?.brand_profile_picture}
              brandId={campaignInfo?.brand_id!}
            />
          </div>
          {isInfluentia() && (
            <div className="flex flex-col gap-y-2">
              {campaignInfo?.stoppedNotifications
                .filter((not) => {
                  return (isValidUUIDv4(influencerId || '') ||
                    isValidUUIDv4(id || '')) &&
                    id !== campaignInfo.brand_id
                    ? not.user_id === influencerId || not.user_id === id
                    : true;
                })
                .map((nt, i) => (
                  <div>
                    <WarningNotifications
                      warning={t('singleCampaign.stopNotifications.warning', {
                        userName:
                          campaignInfo?.influencers?.find(
                            (inf) => inf.id === nt.user_id,
                          )?.name || '',
                      })}
                      startDate={nt.createdAt}
                      setStoppedNotifications={() => {
                        setNotificationsStopped(
                          [...notificationsStopped].splice(i, 1),
                        );
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
          {/* Tab Component from Route File */}
          <Outlet
            context={[campaignId, influencerIdState, campaignInfo?.brand_id]}
          />
        </div>
        <div className="">
          <div
            className={`flex items-end justify-end ${location === 'settings' && isInfluentia() ? '' : 'hidden'}`}
          >
            <Button
              text={t('singleCampaign.delete')}
              id="deleteCampaign"
              style="dark-blue"
              customClassnames="!px-2 !text-light-blue hover:bg-red-status hover:!text-white group"
              iconLeft={
                <TrashIcon className="text-red-status group-hover:text-white" />
              }
              onClick={() => setOpen(true)}
            />
          </div>
          <div
            className={`mt-12 lg:block hidden ${location !== 'general' && 'lg:w-[420px]'}`}
          >
            {campaignInfo &&
              influencerIdState &&
              campaignIdState &&
              location !== 'general' && (
                <div className="mt-12 lg:block hidden lg:w-[420px]">
                  <SingleCampaignInfo
                    campaignInfo={{ ...campaignInfo }}
                    influencerInfo={{ ...singleCampaignInfo! }}
                    refetch={refetch}
                    isBrand={
                      idState !== undefined && idState !== influencerIdState
                    }
                    refetchTabs={refetchTabs}
                    influencerId={influencerIdState}
                    campaignId={campaignIdState}
                    location={location}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </RefetchLateral.Provider>
  );
}

export default SingleCampaign;
