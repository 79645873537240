/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import FullCalendar from '@fullcalendar/react';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { type ReactNode, useEffect, useMemo, useRef } from 'react';

const StyleWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <style>{`  
        .fc-day {
          border: none !important;
        }
        tbody:before {
          line-height: 5px;
          content: "\\x200C";
          display: block;
          visibility: hidden;
        }
        .fc-header-toolbar {
          margin-bottom: 0px !important;
          font-family: 'Montserrat', sans-serif !important;
          font-size: 18px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: normal !important;
          text-transform: capitalize;
        }
        .fc-col-header-cell {
          background: #213A51 !important;
          border: none !important;
          font-weight: 500 !important;

        }
        .fc-view, fc-col-header, .fc-scrollgrid  {
          border: none !important;
          text-transform: capitalize;
        }
        .fc .fc-scrollgrid-section > * {
          border: none !important;
        }
        .fc-daygrid-day-events {
          display: none !important;
        }
        .fc-daygrid-day-number {
          width: 100% !important;
        }
        .fc-day-today {
          background: transparent !important;
        }
      `}</style>
      {children}
    </>
  );
};

type SmallCalendarProps = {
  dates: { date: string; campaign_name: string; user_name: string }[];
};

export function SmallCalendar({ dates }: SmallCalendarProps) {
  const calendarRef = useRef<FullCalendar>(null);

  /**
   * Function to add new events
   */
  const addEvent = (event: any) => {
    if (!calendarRef.current) return;

    const calendar = calendarRef.current.getApi();
    const eventObj = calendar.getEventById(event.id);

    if (eventObj) {
      // @ts-ignore
      eventObj.mutate({ standardProps: { ...event } });
    } else {
      calendar.addEvent(event);
    }
  };

  useEffect(() => {
    dates.forEach(addEvent);
  }, [dates]);

  const language = useMemo(() => {
    if (!['en', 'de', 'es'].includes(i18next.language)) return 'en';
    return i18next.language;
  }, [i18next.language]);

  return (
    <StyleWrapper>
      <FullCalendar
        ref={calendarRef}
        headerToolbar={{
          start: 'title',
          center: '',
          end: '',
        }}
        aspectRatio={1.5}
        nowIndicator={false}
        firstDay={1}
        locale={language}
        dayCellContent={(info) => {
          const datesArray = dates?.map((d) => d.date) || [];
          const hasEvents = datesArray.includes(
            dayjs(info.date).format('YYYY-MM-DD'),
          );
          console.log(hasEvents, info, dates);
          const isToday = dayjs(info.date).isSame(dayjs(), 'day');
          const getColor = () => {
            if (isToday && hasEvents) return 'bg-violet-status text-white';
            if (isToday) return 'text-dark-blue bg-white';
            if (hasEvents) return 'bg-influentia-light-blue text-dark-blue';
            return 'text-white';
          };
          return (
            <div className="w-full flex items-center justify-center size-10">
              <div
                className={`${getColor()} rounded-md size-8 flex items-center justify-center`}
              >
                {info.dayNumberText}
              </div>
            </div>
          );
        }}
        titleFormat={{ month: 'long' }}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        height="100%"
        contentHeight="auto"
      />
    </StyleWrapper>
  );
}
