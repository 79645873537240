import 'dayjs/locale/es';

import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserIcon } from '../assets';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { Button } from '../components/common/Button';
// import { BrandForm } from '../components/users/brandForm';
import { UserForm } from '../components/users/userForm';
import { ROUTES } from '../config/routes/config';
import { GET_BRAND } from '../graphql/brands/query';
import { GET_PROFILE } from '../graphql/user/query';
import type { ResponseProfile } from '../types';

declare global {
  interface Window {
    BeforeInstallPromptEvent: Event;
    deferredPrompt: any;
    installedApp: boolean;
  }
}

dayjs.extend(localizedFormat);
dayjs.locale('es');

function Profile() {
  const { t } = useTranslation('common');

  const [user, setUser] = useState<any>();
  const { refetch: refetchUser } = useQuery<ResponseProfile>(GET_PROFILE, {
    onCompleted: (data) => {
      setUser(data.profile.data);
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleInstallAppClick = async () => {
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt();
      const { outcome } = await window.deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      window.deferredPrompt = null; // Reset the deferred prompt
    }
  };

  const { data, refetch } = useQuery(
    // const { loading } = useQuery<ResSingleCampaignType>(
    GET_BRAND,
    {
      variables: {
        brandId: user && user.brand_id ? user.brand_id : '',
      },
      onError: () => {
        // TODO: SHOW MISSING CAMPAIGN ID NOTIFICATION
      },
      skip: !(user && user.brand_id),
    },
  );

  return (
    <div className="w-full flex flex-col gap-y-5">
      <div className="flex flex-row justify-between">
        <Breadcrumbs
          icon={<UserIcon />}
          breadcrumbs={[
            {
              text: t('profile.title'),
              link: ROUTES.PROFILE,
            },
          ]}
        />
        {window.deferredPrompt && !window.installedApp && (
          <Button
            style="light-blue"
            type="button"
            text={t('profile.installApp')}
            id="installApp"
            onClick={handleInstallAppClick}
          />
        )}
      </div>
      {user && (
        <>
          <UserForm view="profile" info={user} refetch={refetchUser} />
          {/* {user.role === 'Brand' && data && (
            <BrandForm info={data?.brand?.data} refetch={refetch} />
          )} */}
        </>
      )}
    </div>
  );
}

export default Profile;
