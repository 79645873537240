import { useRecoilValue } from 'recoil';

import type { PERMISSIONS } from '../config/constants';
import { ROLES, SUBROLES } from '../config/constants';
import { userAtom } from '../states/atoms';

type RoleType = typeof ROLES;
type SubroleType = typeof SUBROLES;
type PermissionValues = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];

export const usePermissions = () => {
  const user = useRecoilValue(userAtom);
  const userLoaded = !!user.id;

  const checkRole = (role: keyof RoleType) => {
    return user.role.includes(role.valueOf());
  };

  const checkSubrole = (role: keyof SubroleType) => {
    return user.role.includes(role.valueOf());
  };

  // Influencer role
  const isInfluencer = () => user?.role?.includes(ROLES.influencer);

  // Influentia role
  const isInfluentia = () => user?.role?.includes(ROLES.influentia);
  // Influentia subroles
  const isInfluentiaAccountManager = () =>
    user?.role?.includes(ROLES.influentia) &&
    user.subrole?.includes(SUBROLES.accountManager);
  const isInfluentiaOperationsManager = () =>
    user?.role?.includes(ROLES.influentia) &&
    user.subrole?.includes(SUBROLES.operationsManager);
  const isInfluentiaExternal = () =>
    user?.role?.includes(ROLES.influentia) &&
    user.subrole?.includes(SUBROLES.external);

  // Brand role
  const isBrand = () => user?.role?.includes(ROLES.brand);

  const checkPermission = (permission: PermissionValues) => {
    return user.permissions?.includes(permission);
  };

  return {
    isInfluencer,
    isInfluentia,
    isBrand,
    checkRole,
    userLoaded,
    isInfluentiaAccountManager,
    isInfluentiaOperationsManager,
    isInfluentiaExternal,
    checkSubrole,
    checkPermission,
  };
};
