import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { Tooltip } from 'react-tooltip';

import { TooltipIcon } from '../../assets';
import { CURRENCY_TYPES } from '../../config/constants';
import { Error } from '../common/Error';
import { MoneyCurrencyDropdown } from './MoneyCurrencyDropdown';

type Props = {
  label?: string;
  placeholder?: string;
  control?: any;
  validation?: any;
  errors?: any;
  icon?: any;
  id?: string;
  max?: number;
  name: string;
  type?: string;
  height?: 'h-[41px]' | 'h-[25px]' | 'h-[33px]';
  onMouseLeave?: () => void;
  currencySelect?: boolean;
  registerName?: string;
  currentCurrency?: any;
  tooltipText?: string;
  confirmPopup?: boolean;
  onChangeFunction?: () => void;
};

export const MoneyInput = ({
  label,
  placeholder,
  control,
  validation,
  errors,
  icon,
  name,
  type = 'default',
  height = 'h-[41px]',
  onMouseLeave,
  currencySelect = false,
  registerName = 'currency',
  currentCurrency,
  confirmPopup = false,
  tooltipText,
  onChangeFunction,
}: Props) => {
  const INPUT_CLASSNAME = `${icon ? 'pr-3 pl-9' : 'pl-1 pr-3'} ${errors ? 'border border-red-600' : 'border-none'} text-dark-blue block ${height} w-full py-[8px] text-sm font-medium placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0 ${currencySelect ? 'rounded-e-[4px]' : 'rounded-[4px]'}`;
  let labelClassname = 'my-2 block text-[14px] font-[600]';
  if (type === 'blue') {
    labelClassname =
      'my-1.5 font-medium text-[10px] text-influentia-light-blue block';
  }
  return (
    <div className="w-full">
      <div className="flex flex-row">
        {label && (
          <label htmlFor={label} className={labelClassname}>
            {label}
          </label>
        )}
        <div
          data-tooltip-id={`tooltip-${label}`}
          data-tooltip-content={tooltipText}
          className={`flex items-center justify-center ml-2 ${tooltipText ? 'cursor-pointer' : 'hidden'}`}
        >
          <TooltipIcon className="text-influentia-light-blue cursor-pointer" />
          <Tooltip
            className="!bg-dark-blue !opacity-100 !text-white font-montserrat !p-1 !text-sm rounded-10 max-w-[300px] text-center z-50"
            noArrow
            id={`tooltip-${label}`}
            place="bottom"
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex flex-row">
          <div className={`flex flex-row`}>
            <div className={`${!currencySelect && 'hidden'} flex flex-row`}>
              <MoneyCurrencyDropdown
                confirmPopup={confirmPopup}
                options={CURRENCY_TYPES.map((money: string) => ({
                  value: money,
                  label: money,
                }))}
                isClearable={false}
                height={height}
                control={control}
                validation={validation}
                name={registerName}
              />
              <div className={`bg-white flex items-center`}>
                <div className="bg-[#B5B5B5] w-px h-[70%]"></div>
              </div>
            </div>
            <Controller
              control={control}
              rules={validation}
              name={name}
              render={({ field: { onChange, value, ...rest } }) => (
                <NumericFormat
                  value={value === undefined || value === null ? '' : value}
                  thousandSeparator={'.'}
                  decimalSeparator=","
                  thousandsGroupStyle="thousand"
                  decimalScale={2}
                  fixedDecimalScale
                  suffix={currentCurrency || '€'}
                  onChange={(val: any) => {
                    onChange(
                      val.target.value
                        .replace(/["%"."€"."$"."(".")"]/gi, '')
                        .replace(',', '.') || null,
                    );
                    if (onChangeFunction) onChangeFunction();
                  }}
                  onBlur={onMouseLeave}
                  onBlurCapture={() => {}}
                  onChangeCapture={() => {}}
                  onPointerLeave={() => {}}
                  placeholder={placeholder}
                  className={INPUT_CLASSNAME}
                  autoComplete="off"
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="mt-1">{errors && <Error error={errors.message} />}</div>
    </div>
  );
};
