import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { loadingAuthAtom } from '../../states/atoms';

const RequireAuth = ({ children }: any) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [loading, setLoading] = useRecoilState(loadingAuthAtom);

  useEffect(() => {
    if (!isLoading) setTimeout(() => setLoading(false), 500);
  }, [isLoading]);

  if (!isAuthenticated && !loading) {
    return (
      <Navigate
        to={{
          pathname: '/',
        }}
        replace={true}
      />
    );
  }

  return <>{children}</>;
};

export default RequireAuth;
