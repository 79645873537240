import { useMemo } from 'react';

import { InfluentiaLoaderChristmas, InfluentiaLogoBig } from '../../assets';
import { isInChristmasRange, isInTheThreeKingsRange } from '../../utils/utils';

const Snowflakes = ({ count = 75 }) => {
  const getRandom = (max: number, min = 0) => Math.random() * (max - min) + min;

  return (
    <div className="overflow-hidden bg-transparent">
      {Array.from({ length: count }).map((_, i) => {
        const size = `${getRandom(1, 0.2).toFixed(2)}vw`;
        const leftIni = `${getRandom(20, -10).toFixed(2)}vw`;
        const leftEnd = `${getRandom(20, -10).toFixed(2)}vw`;
        const left = `${getRandom(100).toFixed(2)}vw`;
        const animationDuration = `${(8 + getRandom(10)).toFixed(2)}s`;
        const animationDelay = `${(-getRandom(10)).toFixed(2)}s`;

        return (
          <div
            key={i}
            className="absolute bg-white rounded-full"
            style={{
              width: size,
              height: size,
              left,
              animation: `snowfall ${animationDuration} linear infinite`,
              animationDelay,
            }}
          ></div>
        );
      })}
      <style>
        {`
          @keyframes snowfall {
            0% {
              transform: translateY(-5000%);
            }
            100% {
              transform: translateY(5000%);
            }
          }
        `}
      </style>
    </div>
  );
};

export const AuthLoader = () => {
  const showChristmasLoader = useMemo(() => {
    return isInChristmasRange(new Date()) || isInTheThreeKingsRange(new Date());
  }, []);
  const Logo = useMemo(() => {
    if (isInChristmasRange(new Date()) || isInTheThreeKingsRange(new Date()))
      return <InfluentiaLoaderChristmas className="" />;
    return <InfluentiaLogoBig />;
  }, []);
  return (
    <>
      <div className="absolute top-0 left-0 z-10 flex min-h-screen size-full items-center justify-center overflow-hidden bg-influentia-black">
        <div className="relative">
          <div className="opacity-20">{Logo}</div>
          <div className="absolute top-0 left-0 animate-loading-icon overflow-hidden">
            {Logo}
          </div>
        </div>
        {showChristmasLoader && <Snowflakes />}
      </div>
    </>
  );
};
