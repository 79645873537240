import 'dayjs/locale/es';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { CampaignIcon } from '../assets';
import { SubrowTable } from '../components/campaigns/SubrowsTable';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { ActiveFilters } from '../components/filters/ActiveFilters';
import { CampaignFiltersPopover } from '../components/filters/CampaignFilters';
import { TableSearchInput } from '../components/inputs/TableSearchInput';
import { CreateCampaign } from '../components/popups/CreateCampaign';
import DataTable from '../components/tables/DataTable';
import type { ExtraInfoCampaignHeader } from '../components/tables/headers/campaigns';
import { getCampaignsTableHeaders } from '../components/tables/headers/campaigns';
import { replaceIds, ROUTES } from '../config/routes/config';
import { GET_CAMPAIGNS_LIST } from '../graphql/campaign/query';
import { usePermissions } from '../hooks/usePermissions';
import { useTableDataAndFilters } from '../hooks/useTableDataAndFilters';
import { campaignFilterAtom, userAtom } from '../states/atoms';
import type {
  CampaignFilters,
  CampaignTable,
  ResCampaignsList,
} from '../types';

dayjs.extend(localizedFormat);
dayjs.locale('es');

function Campaigns() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { isInfluencer, checkPermission } = usePermissions();
  const location = useLocation();
  const { id: userId } = useRecoilValue(userAtom);

  // Extract the status from the URL query parameters
  const params = new URLSearchParams(location.search);
  const status = params.get('status');

  /**
   * Hook in charge of the logic of the data, filtering and pagination
   */
  const {
    data: campaigns,
    resetPagination,
    total,
    error,
    loading,
    filters,
    setFilters,
    table,
    refetch,
  } = useTableDataAndFilters<
    CampaignFilters,
    CampaignTable,
    ExtraInfoCampaignHeader,
    ResCampaignsList
  >({
    filterAtom: campaignFilterAtom,
    getColumns: getCampaignsTableHeaders,
    columnsExtras: { isInfluencer: isInfluencer() },
    responseAccessKey: 'campaigns',
    query: GET_CAMPAIGNS_LIST,
    subrowAccesor: 'users',
  });

  useEffect(() => {
    // Set the filters when the component mounts or when the status changes
    if (status && status === 'pending') {
      setFilters((prevFilters) => ({ ...prevFilters, status: [status] }));
    }
  }, [status]);

  /**
   * Handles click on any row of the table and navigates to single campaign
   * @param id id of the row clicked
   */
  const handleTableClick = (id: string, influencer_id?: string) => {
    const campaign = campaigns.find((c) => c.id === id);
    if (campaign)
      navigate(
        replaceIds({
          url: ROUTES.SINGLE_CAMPAIGN_GENERAL,
          params: [
            {
              campaignId: campaign.id,
            },
            {
              id: isInfluencer() ? userId : influencer_id || 'campaign',
            },
          ],
        }),
      );
  };

  return (
    <div className="w-full flex flex-col gap-y-5">
      <Breadcrumbs
        icon={<CampaignIcon />}
        breadcrumbs={[
          {
            text: t('campaigns.title'),
          },
        ]}
      />
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row justify-between">
          <div>
            <TableSearchInput
              placeholder={t('campaigns.searchInputPlaceholder')}
              filters={{ ...filters }}
              setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
              searchKey="campaignName"
            />
          </div>
          <div className="flex flex-row gap-x-2">
            <CampaignFiltersPopover
              setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
              filters={filters}
            />
            <CreateCampaign refetch={refetch} />
          </div>
        </div>
        <ActiveFilters
          type="campaign"
          filters={filters}
          setFilters={setFilters}
        />
        <DataTable
          error={error}
          handleRowClick={(id) => handleTableClick(id)}
          handleSubrowClick={(id, influencer_id) => {
            handleTableClick(id, influencer_id);
          }}
          loading={loading}
          table={table}
          total={total}
          tableId="CampaignsTable"
          sorting={{ ...filters }}
          SubrowsComponent={SubrowTable}
        />
      </div>
    </div>
  );
}

export default Campaigns;
