import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { AddClientIcon } from '../../assets';
import { GET_BRANDS } from '../../graphql/brands/query';
import { GET_USERS } from '../../graphql/user/query';
import type { ResponseDataBrandList, ResponseDataUsersList } from '../../types';
import { Error } from '../common/Error';
import { ModalPortal } from '../common/Portal';
import { BrandImage } from '../images/BrandImage';
import { UsersImages } from '../images/UserImages';
import { BrandPopUp } from '../popups/BrandPopUp';
import { InfluencerPopUp } from '../popups/InfluencerPopUp';
import { GenericDropdown } from './GenericDropdown';

type UserDropdownProps = {
  type: 'brand' | 'influencer';
  control: any;
  validation?: any;
  name: string;
  label?: string;
  placeholder?: string;
  multi?: boolean;
  searchable?: boolean;
  storeFullOption?: boolean;
  hasAddButton?: boolean;
  error?: string;
  isClearable?: boolean;
};

export const UserDropdown = ({
  type,
  control,
  name,
  label,
  validation,
  placeholder,
  multi = false,
  searchable = false,
  storeFullOption = false,
  hasAddButton = false,
  error,
  isClearable = true,
}: UserDropdownProps) => {
  const [options, setOptions] =
    useState<{ value: string; label: string; image?: string }[]>();
  const [open, setOpen] = useState(false);
  const [createdUser, setCreatedUser] = useState<string | null>(null);

  useEffect(() => {
    if (createdUser) {
      const newUserOption = options?.find(
        (option) => option.value === createdUser,
      );
      if (newUserOption) {
        // setValue(name, storeFullOption ? newUserOption : newUserOption.value);
      }
    }
  }, [createdUser, options, name, storeFullOption]);

  const [
    getInfluencers,
    { loading: loadingInfluencers, refetch: refetchInfluencers },
  ] = useLazyQuery<ResponseDataUsersList>(GET_USERS, {
    variables: { role: 'Influencer' },
  });

  const [getBrands, { loading: loadingBrands, refetch: refetchBrands }] =
    useLazyQuery<ResponseDataBrandList>(GET_BRANDS);

  const getBrandsDB = () => {
    getBrands({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setOptions(
          data.brands.data.map((b) => ({
            label: b.name,
            value: b.id,
            image: b.profile_picture || '',
          })),
        );
      },
    });
  };
  const getInfluencersDB = () => {
    getInfluencers({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',

      onCompleted: (data) =>
        setOptions(
          data.users.data.map((u) => ({
            label: u.name,
            value: u.id,
            image: u.profile_picture || '',
          })),
        ),
    });
  };

  useEffect(() => {
    if (type && !options) {
      if (type === 'brand') getBrandsDB();
      else if (type === 'influencer') getInfluencersDB();
    }
  }, [type]);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row items-end gap-1">
        <GenericDropdown
          isClearable={isClearable}
          control={control}
          name={name}
          storeFullOption={storeFullOption}
          label={label}
          isSearchable={searchable}
          options={options || []}
          isMulti={multi}
          validation={validation}
          isLoading={loadingInfluencers || loadingBrands}
          placeholder={placeholder || label || ''}
          formatLabelOption={({
            label: labelOption,
            image,
          }: {
            label: string;
            image?: string;
          }) => (
            <div className="flex flex-row items-center gap-x-2">
              {type === 'brand' && (
                <BrandImage size="size-[20px]" image={image} />
              )}
              {type === 'influencer' && (
                <UsersImages
                  size="size-[20px]"
                  userCount={1}
                  userImages={[image || '']}
                />
              )}
              <span>{labelOption}</span>
            </div>
          )}
        />
        <div
          className={`w-[46px] h-[41px] bg-influentia-light-blue flex items-center justify-center rounded-[4px] cursor-pointer ${hasAddButton ? '' : 'hidden'}`}
          onClick={() => setOpen(true)}
        >
          <AddClientIcon />
        </div>
        {open && (
          <ModalPortal>
            {type === 'brand' && (
              <BrandPopUp
                setOpen={setOpen}
                redirect={false}
                refetch={() => getBrandsDB()}
                setCreatedBrand={setCreatedUser}
              />
            )}
            {type === 'influencer' && (
              <InfluencerPopUp
                setOpen={setOpen}
                redirect={false}
                refetch={() => getInfluencersDB()}
                setCreatedInfluencer={setCreatedUser}
              />
            )}
          </ModalPortal>
        )}
      </div>
      {error && <Error error={error} />}
    </div>
  );
};
