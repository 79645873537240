import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AddUserButtonIcon, UsersIcon } from '../../assets';
import { INFLUENTIA_USER_SUBROLES } from '../../config/constants';
import { replaceIds, ROUTES } from '../../config/routes/config';
import { CREATE_USER } from '../../graphql/user/mutation';
import type { ResCreateUserType } from '../../types';
import { Button } from '../common/Button';
import { GenericDropdown } from '../inputs/GenericDropdown';
import { TextInput } from '../inputs/TextInput';
import { PopupLayout } from './PopupLayout';

type TCreateInfluentiaUser = {
  name: string;
  email: string;
  subrole: string;
};

export const CreateInfluentiaUser = ({ refetch }: { refetch: any }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<TCreateInfluentiaUser>({
    defaultValues: {
      subrole: 'Account Manager',
    },
  });

  const [createUser, { loading }] = useMutation<ResCreateUserType>(CREATE_USER);
  const [notCreated, setNotCreated] = useState('');
  const onSubmit = (data: TCreateInfluentiaUser) => {
    createUser({
      variables: {
        name: data.name,
        email: data.email,
        role: 'Influentia',
        subrole: data.subrole,
      },
      onCompleted: (res) => {
        navigate(
          replaceIds({
            url: ROUTES.SINGLE_INFLUENTIA_USER,
            params: [{ id: res.createUser.data || '' }],
          }),
        );
        reset();
        setOpen(false);
        refetch();
        setNotCreated('');
      },
      onError: (error) => {
        setNotCreated(error.message);
      },
    });
  };

  const closeModal = () => {
    reset();
    setOpen(false);
  };
  useEffect(() => {
    if (notCreated === '') return;
    setTimeout(() => {
      setNotCreated('');
    }, 5000);
  }, [notCreated]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style="light-blue"
        id="createInfluentia"
        text={t('influentiaUsers.createUser')}
        iconLeft={<AddUserButtonIcon />}
      />
      {open && (
        <PopupLayout
          title={t('influentiaUsers.createUserTitle')}
          closeModal={closeModal}
          icon={<UsersIcon />}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-y-5 mt-5"
          >
            <div className="flex flex-row gap-x-8 w-full">
              <TextInput
                register={register}
                registerName="name"
                type="text"
                label={t('influentiaUsers.createName')}
                placeholder={t('influentiaUsers.createNamePlaceholder')}
                validation={{ required: t('influentiaUsers.nameRequired') }}
                errors={errors.name}
              />
              <TextInput
                register={register}
                registerName="email"
                type="text"
                label={t('influentiaUsers.createEmail')}
                placeholder={t('influentiaUsers.createEmailPlaceholder')}
                validation={{
                  required: t('influentiaUsers.emailRequired'),
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t('influentiaUsers.emailInvalid'),
                  },
                }}
                errors={errors.email}
              />
              <GenericDropdown
                label={t('singleInfluencer.role')}
                // height="h-[37px]"
                isClearable={false}
                isSearchable={true}
                control={control}
                placeholder={t('singleInfluencer.role')}
                name="subrole"
                options={INFLUENTIA_USER_SUBROLES.map((o: string) => ({
                  value: o,
                  label: o,
                }))}
              />
            </div>
            {notCreated && notCreated !== '' && (
              <span className="text-red-status">{notCreated}</span>
            )}
            <div className="flex justify-end">
              <div className="w-[150px]">
                <Button
                  onClick={() => {}}
                  style="light-blue"
                  id="createInfluentiaSave"
                  text={t('global.save')}
                  customClassnames="w-full justify-center"
                  type="submit"
                  loading={loading}
                />
              </div>
            </div>
          </form>
        </PopupLayout>
      )}
    </>
  );
};
