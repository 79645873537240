import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { CheckBoxReferralIcon } from '../../../assets';
import { CURRENCY_TYPES } from '../../../config/constants';
import { RefetchLateral } from '../../../context/refetchLateral/refetchProvider';
import { UPDATE_CAMPAIGN_RECURRENT } from '../../../graphql/campaign/mutation';
import { GET_SETTING_RECURRENT } from '../../../graphql/campaign/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePrompt } from '../../../hooks/usePrompt';
import { Button } from '../../common/Button';
import { MoneyCurrencyDropdown } from '../../inputs/MoneyCurrencyDropdown';
import { TextInput } from '../../inputs/TextInput';
import { UserDropdown } from '../../inputs/UserDropdown';
import { UserDropdownList } from '../../inputs/UserDropdownList';
import DescriptionPlatforms from '../settings/Description';
import DisclaimerPlatforms from '../settings/Disclaimer';

type Settings = {
  campaignRecurrentSettings: {
    data: {
      id: string;
      name: string;
      currency: string;
      brand: {
        id: string;
        name: string;
      };
      briefing: {
        id: string;
        name: string;
        url: string;
      };
      influencers: {
        id: string;
        name: string;
        profile_picture: string;
      }[];
      accountManagers: {
        id: string;
        name: string;
        profile_picture: string;
      }[];
      disclaimers: {
        id: string;
        platform: string;
        disclaimer: string;
      }[];
      descriptions: {
        id: string;
        description: string;
        platform: string;
      }[];
    };
  };
};

export const SingleCampaignSettingsRecurrentTab = () => {
  const [campaignId]: [string] = useOutletContext();
  const { t } = useTranslation('common');

  const { refetchLateral, refetchTabs } = useContext(RefetchLateral);

  const methods = useForm<Settings['campaignRecurrentSettings']['data']>();
  const {
    control,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = methods;

  const {
    fields: descriptions,
    append: appendDescription,
    remove: removeDescription,
    update: updateDescription,
  } = useFieldArray({
    name: 'descriptions',
    control,
  });

  const {
    fields: disclaimers,
    append: appendDisclaimer,
    remove: removeDisclaimer,
    update: updateDisclaimer,
  } = useFieldArray({
    name: 'disclaimers',
    control,
  });

  const [dirty, setDirty] = useState(false);
  const { refetch } = useQuery<Settings>(GET_SETTING_RECURRENT, {
    variables: {
      campaignId,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const info = data.campaignRecurrentSettings.data;
      // include all the influencers excepts already in influencers
      reset({
        ...info,
        influencers: info.influencers.map((i) => ({
          value: i.id,
          id: i.id,
        })) as any,
        accountManagers: info.accountManagers.map((i) => ({
          value: i.id,
          id: i.id,
        })) as any,
      });
    },
  });

  const addDescription = () => {
    appendDescription({
      id: Math.floor(Math.random() * 1000).toString(),
      description: '',
      platform: '',
    });
  };

  const addDisclaimer = () => {
    appendDisclaimer({
      id: Math.floor(Math.random() * 1000).toString(),
      disclaimer: '',
      platform: '',
    });
  };

  const deleteDescription = (index: number) => {
    removeDescription(index);
  };

  const deleteDisclaimer = (index: number) => {
    removeDisclaimer(index);
  };

  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [updateCampaign, { loading }] = useMutation(UPDATE_CAMPAIGN_RECURRENT);

  const handleSave = (data: Settings['campaignRecurrentSettings']['data']) => {
    const headers: any = {
      'x-apollo-operation-name': 'setCampaignRecurrentSettings',
    };

    updateCampaign({
      variables: {
        campaignId,
        currency: data.currency,
        name: data.name,
        descriptions: data.descriptions.map((description) => {
          return {
            description: description.description,
            platform: description.platform,
          };
        }),
        disclaimers: data.disclaimers.map((disclaimer) => {
          return {
            disclaimer: disclaimer.disclaimer,
            platform: disclaimer.platform,
          };
        }),
        brandId: data.brand.id,
        influencers: data.influencers
          .map((influencer: any) => influencer.value)
          .filter((id) => id),
        accountManagers: data.accountManagers
          .map((influencer: any) => influencer.value)
          .filter((id) => id),
      },
      context: {
        headers,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
        setDirty(false);
        reset({}, { keepValues: true });
        refetchLateral();
        refetchTabs();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  usePrompt({
    isDirty: Object.keys(dirtyFields).length > 0 || dirty,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="flex lg:flex-row flex-col gap-5">
          <TextInput
            type="text"
            label={t('singleCampaign.campaignName')}
            selectedValue={watch('name')}
            onSelectChange={(value) =>
              setValue('name', value as string, { shouldDirty: true })
            }
            tooltipText={t('singleCampaignRecurrent.campaignNameTooltip')}
          />
          <div className="flex flex-row w-full gap-5">
            <div className="w-full">
              <UserDropdown
                control={control}
                name="brand.id"
                label={t('campaigns.createClient')}
                placeholder={t('campaigns.createClientPlaceholder')}
                validation={{ required: t('campaigns.clientRequired') }}
                type="brand"
                isClearable={false}
                // error={errors.client?.message}
              />
            </div>
            <div className="w-full">
              <MoneyCurrencyDropdown
                options={CURRENCY_TYPES.map((money: string) => ({
                  value: money,
                  label: money,
                }))}
                joinComponent={false}
                label={t('singleCampaignRecurrent.currency')}
                isClearable={false}
                name="currency"
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1 mt-5">
          <UserDropdownList
            type="influencer"
            accessName="influencers"
            label={t('singleCampaign.addinfluencers')}
            accessKey="value"
            addText={t('campaigns.createInfluencersAdd')}
            placeholderSelects={t('campaigns.createInfluencersPlaceholder')}
            control={control}
            minLength={0}
            trashIconClassname="fill-current text-red-status hover:text-influentia-light-blue"
          />
        </div>
        <div className="flex flex-col gap-1 mt-5">
          <UserDropdownList
            type="influentia"
            accessName="accountManagers"
            label={t('campaigns.createAccountManagers')}
            accessKey="value"
            addText={t('campaigns.createAccountManagersAdd')}
            placeholderSelects={t('campaigns.createAccountManagersPlaceholder')}
            control={control}
            minLength={0}
            trashIconClassname="fill-current text-red-status hover:text-influentia-light-blue"
            buttonClassname="hover:text-influentia-light-blue"
          />
        </div>
        <div className="flex flex-col gap-1 mt-5">
          <label className="my-2 block text-[14px] font-[600]">
            {t('singleCampaign.disclaimer')}
          </label>
          {disclaimers &&
            disclaimers.map((disclaimer, index) => (
              <DisclaimerPlatforms
                key={disclaimer.id}
                disclaimer={disclaimer}
                updateDisclaimer={(d) => updateDisclaimer(index, d)}
                deleteDisclaimer={() => deleteDisclaimer(index)}
              />
            ))}
          <button
            className="w-full flex items-center justify-center"
            onClick={() => addDisclaimer()}
            type="button"
          >
            <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
              <CheckBoxReferralIcon />
              <span className="text-[10px] text-influentia-light-blue font-medium">
                {t('singleCampaign.addDisclaimer')}
              </span>
            </div>
          </button>
        </div>
        <div className="flex flex-col gap-1 mt-5">
          <label className="my-2 block text-[14px] font-[600]">
            {t('singleCampaign.description')}
          </label>
          {descriptions &&
            descriptions.map((description, index) => (
              <DescriptionPlatforms
                key={description.id}
                description={description}
                updateDescription={(d) => updateDescription(index, d)}
                deleteDescription={() => deleteDescription(index)}
              />
            ))}
          <button
            className="w-full flex items-center justify-center"
            onClick={() => addDescription()}
            type="button"
          >
            <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
              <CheckBoxReferralIcon />
              <span className="text-[10px] text-influentia-light-blue font-medium">
                {t('singleCampaign.addDescription')}
              </span>
            </div>
          </button>
        </div>
        <div className="mt-5">
          <Button
            style="light-blue"
            type="submit"
            text={t('global.save')}
            loading={loading}
            customClassnames="px-16"
          />
        </div>
      </form>
    </FormProvider>
  );
};
