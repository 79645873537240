import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { UsersIcon } from '../assets';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { UserForm } from '../components/users/userForm';
import { ROUTES } from '../config/routes/config';
import { GET_SINGLE_USER } from '../graphql/user/query';
import { useNotification } from '../hooks/useNotification';
import type { ResponseSingleUser, SingleUser } from '../types';

export const SingleInfluentia = () => {
  const { id } = useParams();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { notifyWarning } = useNotification();
  const [data, setData] = useState<SingleUser>();
  const { refetch } = useQuery<ResponseSingleUser>(
    // const { loading } = useQuery<ResSingleCampaignType>(
    GET_SINGLE_USER,
    {
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        console.log(res);
        if (res.user.code !== 200) navigate(ROUTES.INFLUENTIA_USERS);
        setData(res.user.data);
      },
      onError: () => {
        notifyWarning(t('global.errorSingleInfluentia'));
        navigate(ROUTES.INFLUENTIA_USERS);
      },
      skip: !id,
    },
  );
  return (
    <div className="w-full flex flex-col gap-y-5">
      <Breadcrumbs
        icon={<UsersIcon />}
        breadcrumbs={[
          {
            text: t('singleInfluentia.Breadcrumps'),
            link: ROUTES.INFLUENTIA_USERS,
          },
          {
            text: data?.name || '',
          },
        ]}
      />
      <UserForm view="influentia" info={data} refetch={refetch} />
    </div>
  );
};
