import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  BellIcon,
  BellNotificationIcon,
  DefaultBrandIcon,
  DefaultInfluencerIcon,
  DefaultInfluentiaIcon,
  InfluentiaLogo,
  InfluentiaLogoChristmas,
  InfluentiaLogoKings,
  InfluentiaLogoNoLetters,
  InfluentiaLogoNoLettersChristmas,
  InfluentiaLogoNoLettersKings,
  MenuIcon,
} from '../../assets';
import { ROUTES } from '../../config/routes/config';
import { HAS_UNREAD_NOTIFICATIONS } from '../../graphql/notifications/query';
import { usePermissions } from '../../hooks/usePermissions';
import { userAtom } from '../../states/atoms';
import type { ResHasUnreadNotifications } from '../../types/notifications';
import { isInChristmasRange, isInTheThreeKingsRange } from '../../utils/utils';
import { MenuModal } from './MenuModal';
import { NotificationModal } from './NotificationModal';

export const Header = () => {
  const user = useRecoilValue(userAtom);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);

  const { isBrand, isInfluencer, isInfluentia } = usePermissions();

  const { refetch } = useQuery<ResHasUnreadNotifications>(
    HAS_UNREAD_NOTIFICATIONS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setHasNotifications(data.hasUnreadedNotifications.data);
      },
      pollInterval: 60000, // Every 60 seconds
    },
  );

  const Logo = useMemo(() => {
    if (isInChristmasRange(new Date())) return <InfluentiaLogoChristmas />;
    if (isInTheThreeKingsRange(new Date()))
      return <InfluentiaLogoKings className="w-[155px] h-[40px]" />;
    return <InfluentiaLogo />;
  }, []);

  const LogoNoLetters = useMemo(() => {
    if (isInTheThreeKingsRange(new Date()))
      return <InfluentiaLogoNoLettersKings className="size-10" />;
    if (isInChristmasRange(new Date()))
      return <InfluentiaLogoNoLettersChristmas className="size-10" />;
    return <InfluentiaLogoNoLetters className="size-8" />;
  }, []);

  return (
    <div className="flex flex-row justify-between items-center mb-5">
      <div className="flex flex-row gap-x-5 items-center">
        <div
          className={`transition-colors duration-200 p-1 rounded-md relative size-8 ${showMenu ? 'text-white' : 'bg-white hover:bg-influentia-light-blue hover:text-white text-dark-blue'}`}
        >
          <button onClick={() => setShowMenu(!showNotifications)}>
            <MenuIcon />
          </button>
          {showMenu && (
            <MenuModal
              closeModal={() => {
                setShowMenu(false);
              }}
            />
          )}
        </div>
        <div className="flex items-center justify-center">
          <Link to={ROUTES.DASHBOARD}>
            <div className="sm:hidden block">{LogoNoLetters}</div>
            <div className="sm:block hidden">{Logo}</div>
          </Link>
        </div>
      </div>
      <div className="flex fleex-row gap-x-5 items-center md:px-5">
        <div className="bg-white rounded-full size-7 relative">
          <Link to={ROUTES.PROFILE}>
            {user.profile_picture ? (
              <img
                src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${user.profile_picture}`}
                className="size-full object-cover rounded-full"
              />
            ) : (
              <div className="size-full rounded-full">
                {isBrand() && <DefaultBrandIcon className="size-[inherit]" />}
                {isInfluencer() && (
                  <DefaultInfluencerIcon className="size-[inherit]" />
                )}
                {isInfluentia() && (
                  <DefaultInfluentiaIcon className="size-[inherit]" />
                )}
              </div>
            )}
          </Link>
        </div>
        <div className="relative size-6">
          <button onClick={() => setShowNotifications(!showNotifications)}>
            {hasNotifications ? <BellNotificationIcon /> : <BellIcon />}
          </button>
          {showNotifications && (
            <NotificationModal
              refetchHasNotifications={refetch}
              closeModal={() => setShowNotifications(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
