import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import i18next from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  CameraIcon,
  DefaultInfluencerIcon,
  LogOutIcon,
  TrashIcon,
} from '../../assets';
import {
  INFLUENTIA_USER_SUBROLES,
  LANGUAGES,
  PERMISSIONS,
} from '../../config/constants';
import { ROUTES } from '../../config/routes/config';
import { Confirm } from '../../context/confirm/confirm';
import {
  DELETE_USER,
  UPDATE_PROFILE,
  UPDATE_PROFILE_PICTURE,
  USER_SET_SOCIAL_NETWORK,
} from '../../graphql/user/mutation';
import { useNotification } from '../../hooks/useNotification';
import { usePermissions } from '../../hooks/usePermissions';
import { usePrompt } from '../../hooks/usePrompt';
import { userAtom } from '../../states/atoms';
import type { SingleUser } from '../../types';
import { formatCustomDate } from '../../utils/numberFormatter';
import { changeLanguage, getUserLanguage, urlRegex } from '../../utils/utils';
import { Button } from '../common/Button';
import FileUploaderInvisible from '../common/FileUploaderInvisible';
import { LateralProfileMenu } from '../common/LateralProfileMenu';
import ProfileTabs from '../common/ProfileTabs';
import { GenericDropdown } from '../inputs/GenericDropdown';
import { TextInput } from '../inputs/TextInput';
import { Toggle } from '../inputs/Toggle';

dayjs.extend(utc);

dayjs.extend(timezone);

type UserFormProps = {
  info?: SingleUser;
  refetch: () => void;
  view: 'profile' | 'influencer' | 'influentia';
};

declare namespace Intl {
  type Key =
    | 'calendar'
    | 'collation'
    | 'currency'
    | 'numberingSystem'
    | 'timeZone'
    | 'unit';

  function supportedValuesOf(input: Key): string[];
}

export const UserForm = ({ info, refetch, view }: UserFormProps) => {
  const { t } = useTranslation('common');
  const myUser = useRecoilValue(userAtom);
  const { logout } = useAuth0();
  const [user, setUser] = useState<any>(info);
  const [timeZoneAutomatic, setTimeZoneAutomatic] = useState(
    user?.time_zone_automatic,
  );
  const [automaticLanguage, setAutomaticLanguage] = useState(
    user?.language_automatic,
  );
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
      timezone: info?.time_zone,
      time_zone_automatic: user?.time_zone_automatic,
      language: user?.language || 'es',
      language_automatic: user?.language_automatic,
      subrole: user?.subrole,
      Youtube: user?.platforms?.find((p: any) => p.name === 'Youtube')?.url,
      Instagram: user?.platforms?.find((p: any) => p.name === 'Instagram')?.url,
      TikTok: user?.platforms?.find((p: any) => p.name === 'TikTok')?.url,
      Twitter: user?.platforms?.find((p: any) => p.name === 'Twitter')?.url,
      Substack: user?.platforms?.find((p: any) => p.name === 'Substack')?.url,
      Blog: user?.platforms?.find((p: any) => p.name === 'Blog')?.url,
      Telegram: user?.platforms?.find((p: any) => p.name === 'Telegram')?.url,
    },
  });

  const [deleteUser, { loading: loadingDelete }] = useMutation(DELETE_USER);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const deleteUserDB = () => {
    deleteUser({
      variables: {
        userId: user.id,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'DeleteUser',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        if (location.pathname.includes('influencer')) {
          navigate(ROUTES.INFLUENCERS);
        }
        if (location.pathname.includes('user')) {
          navigate(ROUTES.INFLUENTIA_USERS);
        }
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);
  const [updateProfile1] = useMutation(UPDATE_PROFILE);
  const [updateSocial, { loading: loadingSocial }] = useMutation(
    USER_SET_SOCIAL_NETWORK,
  );

  const onSubmit = (data: any) => {
    updateProfile({
      variables: {
        userId: user.id,
        email: data.email,
        name: data.name,
        phone: data.phone,
        time_zone: data.timezone,
        time_zone_automatic: timeZoneAutomatic,
        language: automaticLanguage ? getUserLanguage() : data.language,
        language_automatic: automaticLanguage,
        hasAccess: false, // without his variable, user won't get updated
        subrole: data.subrole,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
        reset({}, { keepValues: true });
      },
      onError: () => {
        notifySaveError();
      },
    });
    if (
      data.language !== user.language ||
      automaticLanguage !== user.language_automatic
    ) {
      changeLanguage(data.language, automaticLanguage);
    }
  };
  const onSubmitSocial = (data: any) => {
    updateSocial({
      variables: {
        userId: user.id,
        networks: [
          {
            name: 'Youtube',
            url: data.Youtube,
          },
          {
            name: 'Instagram',
            url: data.Instagram,
          },
          {
            name: 'TikTok',
            url: data.TikTok,
          },
          {
            name: 'Twitter',
            url: data.Twitter,
          },
          {
            name: 'Substack',
            url: data.Substack,
          },
          {
            name: 'Blog',
            url: data.Blog,
          },
          {
            name: 'Telegram',
            url: data.Telegram,
          },
        ],
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  useEffect(() => {
    setUser(info);
    reset({
      name: info?.name,
      email: info?.email,
      phone: info?.phone,
      timezone: info?.time_zone,
      time_zone_automatic: info?.time_zone_automatic,
      language: info?.language || 'es',
      language_automatic: info?.language_automatic,
      subrole: info?.subrole,
      Youtube: info?.platforms?.find((p: any) => p.name === 'Youtube')?.url,
      Instagram: info?.platforms?.find((p: any) => p.name === 'Instagram')?.url,
      TikTok: info?.platforms?.find((p: any) => p.name === 'TikTok')?.url,
      Twitter: info?.platforms?.find((p: any) => p.name === 'Twitter')?.url,
      Substack: info?.platforms?.find((p: any) => p.name === 'Substack')?.url,
      Blog: info?.platforms?.find((p: any) => p.name === 'Blog')?.url,
      Telegram: info?.platforms?.find((p: any) => p.name === 'Telegram')?.url,
    });
    setTimeZoneAutomatic(info?.time_zone_automatic);
  }, [info]);

  const { isInfluentia, isInfluencer, checkPermission } = usePermissions();

  const [updateProfilePicture, { loading: loadingFile }] = useMutation(
    UPDATE_PROFILE_PICTURE,
  );
  const uploadFile = (file?: File) => {
    updateProfilePicture({
      variables: {
        file,
        userId: user.id,
      },
      context: {
        headers: {
          'content-type': 'multipart/form-data',
          'x-apollo-operation-name': 'UpdateUserProfilePicture',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const toggleAccess = () => {
    updateProfile1({
      variables: {
        userId: user.id,
        hasAccess: !user.blocked,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  usePrompt({
    isDirty: Object.keys(dirtyFields).length > 0,
  });

  const toggleRef = useRef<{
    toggle: (value: boolean) => void;
  }>(null);

  const [accessOpen, setAccessOpen] = useState(false);

  const lastAccess = user?.last_login
    ? formatCustomDate(user?.last_login, i18next.language) // Passing user's language
    : t('global.never');

  const handleToggleClick = (value: boolean) => {
    if (toggleRef.current) {
      toggleRef.current.toggle(value); // Llama a la función toggle
    }
  };

  return (
    <div className="size-full flex flex-col gap-y-5">
      <Confirm
        id="deleteAccountDialog"
        open={open}
        onConfirm={() => deleteUserDB()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      <Confirm
        open={accessOpen}
        onConfirm={() => toggleAccess()}
        id="accessPortal"
        setOpen={setAccessOpen}
        onCancel={() => {
          handleToggleClick(!user.blocked);
        }}
        type="warning"
        title={
          user?.blocked
            ? t('global.grantAccessTitle')
            : t('global.removeAccessTitle')
        }
        subtitle={
          user?.blocked
            ? t('global.grantAccessSubTitle')
            : t('global.removeAccessSubTitle')
        }
        confirmText={
          user?.blocked
            ? t('global.grantAccessButton')
            : t('global.removeAccessButon')
        }
        cancelText={t('global.cancel')}
      />
      {user && (
        <>
          <div className="lg:hidden flex flex-row gap-5">
            <ProfileTabs location={location} user={user} myUser={myUser} />
          </div>
          <div className="flex flex-row gap-12">
            {!location.pathname.includes('user') && (
              <div className="h-screen sticky top-0 lg:flex hidden">
                <LateralProfileMenu
                  user={user}
                  isProfile={user.id === myUser.id}
                  isBrand={myUser.role === 'Brand'}
                />
              </div>
            )}
            <div className="w-full flex flex-col gap-y-3">
              <div className="flex lg:flex-row flex-col lg:gap-x-5 lg:gap-y-0 gap-y-5 w-full">
                <div className="flex flex-col gap-12 w-full">
                  <div className="flex flex-row">
                    <div className="min-w-[300px] max-w-[300px]">
                      <div className="flex flex-row gap-5 items-center">
                        <div className="group relative size-[96px] items-center flex justify-center">
                          {user && !user.profile_picture && (
                            <DefaultInfluencerIcon className="size-[96px] group-hover:opacity-50" />
                          )}
                          <CameraIcon className="absolute group-hover:block hidden z-[5]" />
                          {user && user.profile_picture && (
                            <img
                              src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${user.profile_picture}`}
                              className={`rounded-full size-[96px] object-cover group-hover:opacity-50`}
                            />
                          )}
                          <FileUploaderInvisible
                            submitFile={uploadFile}
                            loading={loadingFile}
                          />
                        </div>

                        <p className="text-2xl font-bold md:flex-none">
                          {user.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="flex lg:flex-row flex-col gap-12 w-full"
                    >
                      <div className="flex flex-col w-full">
                        <span className="text-[16px] uppercase">
                          <p>{t('singleInfluencer.personalInfoTitle')}</p>
                        </span>
                        <div className="flex flex-col gap-y-5">
                          <div>
                            <div className="px-2.5 md:min-w-[400px]">
                              <TextInput
                                type="text"
                                register={register}
                                registerName="name"
                                label={t('singleInfluencer.nameTitle')}
                                placeholder={t(
                                  'singleInfluencer.namePlaceholder',
                                )}
                              />
                              <TextInput
                                type="text"
                                register={register}
                                registerName="email"
                                label={t('singleInfluencer.emailTitle')}
                                placeholder={t(
                                  'singleInfluencer.emailPlaceholder',
                                )}
                              />
                              <TextInput
                                type="text"
                                registerName="phone"
                                register={register}
                                label={t('singleInfluencer.phoneTitle')}
                                placeholder={t(
                                  'singleInfluencer.phonePlaceholder',
                                )}
                              />
                              {view === 'influentia' && (
                                <GenericDropdown
                                  label={t('singleInfluencer.role')}
                                  // height="h-[37px]"
                                  isClearable={false}
                                  isSearchable={true}
                                  control={control}
                                  placeholder={t('singleInfluencer.role')}
                                  name="subrole"
                                  options={INFLUENTIA_USER_SUBROLES.map(
                                    (o: string) => ({
                                      value: o,
                                      label: o,
                                    }),
                                  )}
                                />
                              )}
                              <div
                                className={`flex-col flex mt-5 ${user.id === myUser.id && 'hidden'}`}
                              >
                                <span className="text-[16px] uppercase">
                                  {t('singleInfluencer.timeZone')}
                                </span>
                                <div className="my-5">
                                  <Toggle
                                    onChange={() => {
                                      setTimeZoneAutomatic(!timeZoneAutomatic);
                                    }}
                                    label={t(
                                      'singleInfluencer.timeZoneAutomatic',
                                    )}
                                    value={user.time_zone_automatic}
                                  />
                                </div>
                                {!timeZoneAutomatic && (
                                  <GenericDropdown
                                    height="h-[37px]"
                                    isClearable={false}
                                    isSearchable={true}
                                    control={control}
                                    isDisabled={timeZoneAutomatic}
                                    placeholder={t(
                                      'singleInfluencer.timeZonePlaceholder',
                                    )}
                                    name="timezone"
                                    options={Intl.supportedValuesOf(
                                      'timeZone',
                                    ).map((timezoneOption: string) => ({
                                      value: timezoneOption,
                                      label: `
                                    ${timezoneOption} 
                                    (${dayjs().tz(timezoneOption).format('Z')})
                                    `,
                                    }))}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col w-full">
                            <div className="flex flex-col h-full justify-between">
                              <div
                                className={`flex-col flex ${user.id !== myUser.id && 'hidden'}`}
                              >
                                <span className="text-[16px] uppercase">
                                  {t('singleInfluencer.timeZone')}
                                </span>
                                <div className="px-2.5 ">
                                  <div className="mt-5 mb-2px-2.5 ">
                                    <Toggle
                                      onChange={() => {
                                        setTimeZoneAutomatic(
                                          !timeZoneAutomatic,
                                        );
                                      }}
                                      label={t(
                                        'singleInfluencer.timeZoneAutomatic',
                                      )}
                                      value={user.time_zone_automatic}
                                      maxWidth="max-w-[260px]"
                                    />
                                  </div>
                                  {!timeZoneAutomatic && (
                                    <GenericDropdown
                                      height="h-[37px]"
                                      isClearable={false}
                                      control={control}
                                      isDisabled={timeZoneAutomatic}
                                      placeholder={t(
                                        'singleInfluencer.timeZonePlaceholder',
                                      )}
                                      name="timezone"
                                      options={Intl.supportedValuesOf(
                                        'timeZone',
                                      ).map((timezoneOption: string) => ({
                                        value: timezoneOption,
                                        label: `
                                    ${timezoneOption} 
                                    (${dayjs().tz(timezoneOption).format('Z')})
                                    `,
                                      }))}
                                    />
                                  )}
                                  <div className="my-2">
                                    <Toggle
                                      onChange={() => {
                                        setAutomaticLanguage(
                                          !automaticLanguage,
                                        );
                                      }}
                                      label={t(
                                        'singleInfluencer.useAutomaticLanguage',
                                      )}
                                      value={user.language_automatic}
                                      maxWidth="max-w-[240px]"
                                    />
                                  </div>
                                  {!automaticLanguage && (
                                    <GenericDropdown
                                      height="h-[37px]"
                                      isClearable={false}
                                      control={control}
                                      isDisabled={automaticLanguage}
                                      placeholder={t(
                                        'singleInfluencer.languageSelectorPlaceholder',
                                      )}
                                      name="language"
                                      options={LANGUAGES.map((language) => {
                                        return {
                                          value: language,
                                          label: t(`languages.${language}`),
                                        };
                                      })}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="pt-2 mt-5 justify-end flex">
                                <Button
                                  text={t('global.save')}
                                  loading={loading}
                                  type="submit"
                                  style="light-blue"
                                  customClassnames="!px-14 flex text-center items-center justify-center w-full md:w-auto"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div
                      className={`mt-8 ${user.id !== myUser.id && '!hidden'} lg:block hidden`}
                    >
                      <Button
                        style="black"
                        iconLeft={<LogOutIcon />}
                        text={t('profile.logout')}
                        customClassnames="flex items-center justify-center !bg-red-status w-full py-1.5 px-2 rounded-[4px] max-w-[150px]"
                        onClick={() =>
                          logout({
                            logoutParams: { returnTo: window.location.origin },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`flex flex-col w-full ${(!isInfluentia() || user.id === myUser.id || user.role === 'Influentia') && !((user && user.role === 'Influencer' && isInfluentia()) || isInfluencer()) && 'hidden'}`}
                >
                  <div
                    className={`mb-7 ${(!isInfluentia() || user.id === myUser.id || user.role === 'Influentia') && 'hidden'}`}
                  >
                    <span className="text-[16px] uppercase">
                      {t('singleInfluencer.access')}
                    </span>
                    <div className="flex flex-row items-center min-w-[250px] max-w-full">
                      <div className="flex flex-col w-full">
                        <div className="flex flex-row gap-3 justify-between">
                          <span className="font-inter text-[14px]">
                            {t('profile.accessPortalInfluentia')}
                          </span>
                          <Toggle
                            ref={toggleRef}
                            value={!user.blocked}
                            minWidth=""
                            onChange={() => {
                              setAccessOpen(true);
                            }}
                          />
                        </div>
                        <div className="flex flex-row gap-1 w-full">
                          <span className="font-medium text-[12px]">
                            {t('profile.lastAcess')}:
                          </span>
                          <span className="font-medium text-[12px] text-influentia-light-blue">
                            {lastAccess}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex flex-col  ${!((user && user.role === 'Influencer' && isInfluentia()) || isInfluencer()) && 'hidden'}`}
                  >
                    <span className="text-[16px] uppercase">
                      {t('singleInfluencer.socialNetworksTitle')}
                    </span>
                    <form onSubmit={handleSubmit(onSubmitSocial)}>
                      <div className="px-2.5 md:min-w-[400px]">
                        <TextInput
                          type="text"
                          register={register}
                          registerName="Youtube"
                          validation={{
                            pattern: urlRegex,
                          }}
                          label="Youtube"
                          errors={errors.Youtube}
                        />
                        <TextInput
                          type="text"
                          register={register}
                          registerName="Instagram"
                          validation={{
                            pattern: urlRegex,
                          }}
                          label="Instagram"
                          errors={errors.Instagram}
                        />
                        <TextInput
                          type="text"
                          register={register}
                          registerName="Twitter"
                          validation={{
                            pattern: urlRegex,
                          }}
                          label="Twitter"
                          errors={errors.Twitter}
                        />
                        <TextInput
                          type="text"
                          register={register}
                          registerName="TikTok"
                          validation={{
                            pattern: urlRegex,
                          }}
                          label="Tik Tok"
                          errors={errors.TikTok}
                        />
                        <TextInput
                          type="text"
                          register={register}
                          registerName="Substack"
                          validation={{
                            pattern: urlRegex,
                          }}
                          label="Substack"
                          errors={errors.Substack}
                        />
                        <TextInput
                          type="text"
                          register={register}
                          registerName="Blog"
                          id="Blog"
                          validation={{
                            pattern: urlRegex,
                          }}
                          label="Blog/Newsletter"
                          errors={errors.Blog}
                        />
                        <TextInput
                          type="text"
                          register={register}
                          registerName="Telegram"
                          id="Telegram"
                          label="Telegram"
                          errors={errors.Telegram}
                        />
                        <div className="pt-2 justify-end flex">
                          <Button
                            text={t('global.save')}
                            id="saveNetworks"
                            loading={loadingSocial}
                            type="submit"
                            style="light-blue"
                            customClassnames="!px-14 flex text-center items-center justify-center md:w-auto w-full"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className={`w-full mt-5 ${user.id === myUser.id ? 'hidden' : ''} ${!checkPermission(PERMISSIONS.DELETE_USER) ? 'hidden' : ''}`}
              >
                <div className="flex w-full">
                  <Button
                    id="deleteAccount"
                    style="black"
                    customClassnames="flex w-full grow-0 shrink-0 items-center justify-center !bg-red-status py-1.5 px-5 rounded-[4px]"
                    iconLeft={<TrashIcon />}
                    loading={loadingDelete}
                    onClick={() => setOpen(true)}
                    text={t('profile.deleteAccount')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${user.id !== myUser.id && '!hidden'} lg:hidden`}>
            <Button
              style="black"
              iconLeft={<LogOutIcon />}
              text={t('profile.logout')}
              customClassnames="flex items-center justify-center !bg-red-status w-full py-1.5 px-2 rounded-[4px] lg:max-w-[150px]"
              onClick={() =>
                logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }
            />
          </div>
        </>
      )}
    </div>
  );
};
