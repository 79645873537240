import { useTranslation } from 'react-i18next';

import {
  ACTION_VARIABLE_PRICE_TYPE,
  CAMPAIGN_VARIABLE_PRICE_TYPE,
} from '../../../config/constants';
import type {
  TInfSettingsAction,
  TInfSettingsRecurrentAction,
} from '../../../types/action';
import { SocialNetworkImage } from '../../images/SocialNetworkImage';
import { GenericDropdown } from '../../inputs/GenericDropdown';
import { MoneyInput } from '../../inputs/MoneyInput';
import { VariablePriceInput } from '../../inputs/VariablePriceInput';

type CampaignPriceSectionsProps = {
  control: any;
  influencer_variable_type: any;
  actions: TInfSettingsAction[] | TInfSettingsRecurrentAction[];
  watch: any;
};

export const CampaignPriceSections = ({
  control,
  influencer_variable_type,
  actions,
  watch,
}: CampaignPriceSectionsProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="flex xl:flex-row flex-col w-full gap-5">
        <div className="w-full flex flex-row gap-x-5 items-center">
          <div>
            <MoneyInput
              name="total_amount"
              label={t('singleCampaign.totalPrice')}
              control={control}
              currentCurrency={watch('total_amountType')}
              tooltipText={t('singleCampaign.totalPriceTooltip')}
            />
          </div>
          <span className="mt-8 -mx-3">+</span>
          <div className="mt-[37px]">
            <VariablePriceInput
              options={CAMPAIGN_VARIABLE_PRICE_TYPE}
              currencySelect={true}
              name="total_variable_amount"
              currentCurrency={
                watch(`influencer_variable_type`) === 'Revenue Share'
                  ? '%'
                  : watch('total_amountType')
              }
              registerName="influencer_variable_type"
              control={control}
              dropdownDisabled
              amountInputDisabled={
                watch('influencer_variable_type') === 'No' ||
                watch('influencer_variable_type') === 'Per Action'
              }
            />
          </div>
        </div>
        <div className="w-full flex flex-row gap-x-5 items-center">
          <div>
            <MoneyInput
              currencySelect={true}
              name="influencer_amount"
              currentCurrency={watch('influencer_amountType')}
              registerName="influencer_amountType"
              label={t('singleCampaign.influencerPrice')}
              control={control}
            />
          </div>
          <span className="mt-8 -mx-3">+</span>
          <div className="mt-[37px]">
            <VariablePriceInput
              options={CAMPAIGN_VARIABLE_PRICE_TYPE}
              currencySelect={true}
              name="influencer_variable_amount"
              currentCurrency={
                watch(`influencer_variable_type`) === 'Revenue Share'
                  ? '%'
                  : watch(`influencer_amountType`)
              }
              registerName="influencer_variable_type"
              control={control}
              amountInputDisabled={
                watch('influencer_variable_type') === 'No' ||
                watch('influencer_variable_type') === 'Per Action'
              }
            />
          </div>
        </div>
      </div>
      {influencer_variable_type === 'Per Action' && (
        <div className="flex flex-col mt-5 gap-5">
          <span>{t('singleCampaign.pricePerAction')}</span>
          {actions.map((a, i) => (
            <div
              key={`importe-${a.id}`}
              className="flex flex-row w-full justify-between gap-x-5"
            >
              <div className="flex flex-row text-sm items-center gap-x-2 font-medium">
                <SocialNetworkImage
                  socialNetwork={a.platform}
                  size="size-[30px]"
                />
                {a.number_of_action && a.type ? (
                  <span>
                    {a.platform} - {a.number_of_action}{' '}
                    {t(`actionType.${a.type}`)}
                  </span>
                ) : (
                  <span>{a.platform}</span>
                )}
              </div>
              <div className="flex flex-row gap-x-5">
                <div className="min-w-[250px]">
                  <GenericDropdown
                    options={ACTION_VARIABLE_PRICE_TYPE.map((val) => ({
                      label: t(`varibale_price_type.${val}`),
                      value: val,
                    }))}
                    isClearable={false}
                    control={control}
                    validation={{}}
                    name={`actions.${i}.variable_type`}
                  />
                </div>
                <div className="flex flex-row gap-x-2 items-center">
                  <p className="text-sm text-nowrap">
                    {t('singleCampaign.totalPrice')}
                  </p>
                  <MoneyInput
                    currencySelect={
                      watch(`actions.${i}.variable_type`) !== 'Revenue Share'
                    }
                    name={`actions.${i}.total_amount`}
                    currentCurrency={
                      watch(`actions.${i}.variable_type`) === 'Revenue Share'
                        ? '%'
                        : watch(`actions.${i}.total_currency`)
                    }
                    registerName={`actions.${i}.total_currency`}
                    control={control}
                  />
                </div>
                <div className="flex flex-row gap-x-2 items-center">
                  <p className="text-sm text-nowrap">
                    {t('singleCampaign.influencerPrice')}
                  </p>
                  <MoneyInput
                    currencySelect={
                      watch(`actions.${i}.variable_type`) !== 'Revenue Share'
                    }
                    name={`actions.${i}.influencer_amount`}
                    currentCurrency={
                      watch(`actions.${i}.variable_type`) === 'Revenue Share'
                        ? '%'
                        : watch(`actions.${i}.total_currency`)
                    }
                    registerName={`actions.${i}.influencer_currency`}
                    control={control}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
