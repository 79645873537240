import { gql } from '@apollo/client';

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign(
    $name: String!
    $brand_id: String!
    $influencers: [String]!
    $accountManagers: [String]!
    $month: String!
  ) {
    createCampaign(
      campaignName: $name
      brandId: $brand_id
      influencerIds: $influencers
      accountManagers: $accountManagers
      month: $month
    ) {
      status
      code
      data
      error
    }
  }
`;
export const CREATE_CAMPAIGN_RECURRENT = gql`
  mutation CreateCampaignRecurrent(
    $name: String!
    $brand_id: String!
    $influencers: [String]!
    $accountManagers: [String]!
  ) {
    createCampaignRecurrent(
      campaignName: $name
      brandId: $brand_id
      influencerIds: $influencers
      accountManagers: $accountManagers
    ) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_INTERNAL_NOTE = gql`
  mutation CreateInternalNote(
    $campaignId: String!
    $influencerId: String!
    $message: String!
    $type: String
  ) {
    createInternalNote(
      campaignId: $campaignId
      influencerId: $influencerId
      message: $message
      type: $type
    ) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_TIMELINE = gql`
  mutation CreateTimeline($pieceId: String!, $description: String!) {
    createTimeline(pieceId: $pieceId, description: $description) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_TIMELINE = gql`
  mutation DeleteTimeline($timelineId: String!) {
    deleteTimeline(timelineId: $timelineId) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_PIECE = gql`
  mutation UpdatePiece($key: String!, $pieceId: String!, $value: String!) {
    updatePiece(key: $key, pieceId: $pieceId, value: $value) {
      status
      code
      error
      data
    }
  }
`;

export const SET_PIECE_AS_PUBLISHED = gql`
  mutation SetPieceAsPublished($pieceId: String!, $link: String!) {
    setPieceAsPublished(pieceId: $pieceId, link: $link) {
      status
      code
      error
      data
    }
  }
`;

export const UPDATE_PUBLISH_DATE = gql`
  mutation UpdateCampaignPublishDates(
    $campaignId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    updateCampaignPublishDates(
      campaignId: $campaignId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      status
      code
      data
      error
    }
  }
`;
export const UPDATE_INFLUENCER_SETTINGS = gql`
  mutation UpdateInfluencerCampaign(
    $influencerId: String!
    $campaignId: String!
    $influencerAmount: Float
    $totalAmount: Float
    $influencerAmountType: String
    $total_variable_amount: Float
    $influencer_variable_type: String
    $influencer_variable_amount: Float
    $referrals: [ReferralInput]
    $actions: [ActionInput]
    $status: String
  ) {
    updateInfluencerCampaign(
      influencerId: $influencerId
      campaignId: $campaignId
      influencerAmount: $influencerAmount
      total_variable_amount: $total_variable_amount
      influencer_variable_type: $influencer_variable_type
      influencer_variable_amount: $influencer_variable_amount
      totalAmount: $totalAmount
      influencerAmountType: $influencerAmountType
      referrals: $referrals
      actions: $actions
      status: $status
    ) {
      status
      code
      data
      error
    }
  }
`;
export const UPDATE_INFLUENCER_SETTINGS_RECURRENT = gql`
  mutation UpdateInfluencerCampaignRecurrent(
    $influencerId: String!
    $campaignId: String!
    $initDate: String
    $influencerAmountType: String
    $finishDate: String
    $cicle: Boolean
    $influencerAmount: Float
    $total_variable_amount: Float
    $influencer_variable_type: String
    $influencer_variable_amount: Float
    $totalAmount: Float
    $paused: Boolean
    $referrals: [ReferralInput]
    $actions: [ActionInputRecurrent]
    $status: String
    $send: Boolean
  ) {
    updateInfluencerCampaignRecurrent(
      influencerId: $influencerId
      campaignId: $campaignId
      total_variable_amount: $total_variable_amount
      influencer_variable_type: $influencer_variable_type
      influencer_variable_amount: $influencer_variable_amount
      initDate: $initDate
      cicle: $cicle
      paused: $paused
      finishDate: $finishDate
      influencerAmount: $influencerAmount
      influencerAmountType: $influencerAmountType
      totalAmount: $totalAmount
      referrals: $referrals
      actions: $actions
      status: $status
      send: $send
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation SetCampaignSettings(
    $campaignId: String!
    $descriptions: [descriptionsInput]
    $disclaimers: [disclaimersInput]
    $name: String
    $money: Float
    $currency: String
    $brandId: String
    $influencers: [String]
    $file: Upload
    $month: String
    $accountManagers: [String]
  ) {
    setCampaignSettings(
      campaignId: $campaignId
      currency: $currency
      descriptions: $descriptions
      disclaimers: $disclaimers
      name: $name
      money: $money
      brandId: $brandId
      influencers: $influencers
      file: $file
      month: $month
      accountManagers: $accountManagers
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_CAMPAIGN_RECURRENT = gql`
  mutation SetCampaignRecurrentSettings(
    $campaignId: String!
    $descriptions: [descriptionsInput]
    $disclaimers: [disclaimersInput]
    $name: String
    $currency: String
    $brandId: String
    $influencers: [String]
    $file: Upload
    $accountManagers: [String]
  ) {
    setCampaignRecurrentSettings(
      campaignId: $campaignId
      descriptions: $descriptions
      disclaimers: $disclaimers
      name: $name
      currency: $currency
      brandId: $brandId
      influencers: $influencers
      file: $file
      accountManagers: $accountManagers
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_CAMPAIGN_KEY = gql`
  mutation UpdateCampaign(
    $key: String!
    $campaignId: String!
    $value: String!
  ) {
    updateCampaign(key: $key, campaignId: $campaignId, value: $value) {
      status
      code
      data
      error
    }
  }
`;
export const UPDATE_CAMPAIGN_RECURRENT_KEY = gql`
  mutation UpdateCampaignRecurrent(
    $key: String!
    $campaignId: String!
    $value: String!
  ) {
    updateCampaignRecurrent(key: $key, campaignId: $campaignId, value: $value) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($campaignId: String!, $recurrent: Boolean) {
    deleteCampaign(campaignId: $campaignId, recurrent: $recurrent) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_CAMPAIGN_RECURRENT = gql`
  mutation DeleteCampaignRecurrent($campaignId: String!) {
    deleteCampaignRecurrent(campaignId: $campaignId) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_RESOURCE = gql`
  mutation CreateResource(
    $campaignId: String!
    $file: Upload
    $link: String
    $linkName: String
    $recurrent: Boolean
  ) {
    createResource(
      campaignId: $campaignId
      file: $file
      link: $link
      linkName: $linkName
      recurrent: $recurrent
    ) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_RESOURCE = gql`
  mutation DeleteResource($resourceId: String!) {
    deleteResource(resourceId: $resourceId) {
      status
      code
      data
      error
    }
  }
`;
