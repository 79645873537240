import './styles/App.css';
import './styles/index.css';
import './styles/custom.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { useRecoilValue } from 'recoil';

import { AuthLoader } from './components/auth/AuthLoader';
import AppRouter from './config/routes';
import { loadingAuthAtom } from './states/atoms';

function App() {
  const loadingAuth = useRecoilValue(loadingAuthAtom);
  return (
    <>
      {loadingAuth && <AuthLoader />}
      <AppRouter />
    </>
  );
}

export default App;
