import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrashIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import PlatformIcons from './influencer/PlatformsIcons';

export default function DescriptionPlatforms({
  description,
  updateDescription,
  deleteDescription,
}: {
  description: {
    id: string;
    description: string;
    platform: any;
  };
  updateDescription: (description: any) => void;
  deleteDescription: any;
}) {
  const { t } = useTranslation('common');
  const platformsString = description.platform ? description.platform : '';
  const [platforms, setPlatforms] = useState(
    platformsString === ''
      ? []
      : platformsString.split(',').map((item: any) => item.trim()),
  );
  const [textDescription, setTextDescription] = useState(
    description.description,
  );

  const handleUpdate = (newPlatforms: string[]) => {
    updateDescription({
      ...description,
      platform: newPlatforms.join(','),
      description: textDescription,
    });
  };
  const onClickPlatform = (platform: string) => () => {
    setPlatforms((prevPlatforms: any) => {
      const updatedPlatforms = prevPlatforms.includes(platform)
        ? prevPlatforms.filter((p: any) => p !== platform)
        : [...prevPlatforms, platform];
      handleUpdate(updatedPlatforms);
      return updatedPlatforms;
    });
  };
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-row gap-3 items-center">
      <div className="w-full">
        <div className="flex flex-row gap-2.5">
          <Confirm
            open={open}
            onConfirm={() => deleteDescription()}
            setOpen={setOpen}
            type="delete"
            title={t('global.deleteTitle')}
            subtitle={t('global.deleteSubtitle')}
            confirmText={t('global.delete')}
            cancelText={t('global.cancel')}
          />
          <textarea
            className="w-full h-[250px] px-3 py-[8px] rounded-[5px] border-none outline-none text-sm text-dark-blue"
            value={textDescription}
            onChange={(value) => {
              if (value.target.value.length > 5000) return;
              setTextDescription(String(value.target.value));
            }}
            onBlur={() => handleUpdate(platforms)}
          />
          <PlatformIcons
            platforms={platforms}
            onClickPlatform={onClickPlatform}
          />
        </div>
      </div>
      <div className="flex items-center justify-center w-px h-4 bg-dark-blue"></div>
      <div
        className={`flex items-center justify-center cursor-pointer`}
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
      </div>
    </div>
  );
}
