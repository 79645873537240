import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrandsIcon } from '../../assets';
import { PERMISSIONS } from '../../config/constants';
import { usePermissions } from '../../hooks/usePermissions';
import { Button } from '../common/Button';
import { BrandPopUp } from './BrandPopUp';

export const CreateBrand = ({ refetch }: { refetch: any }) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const { checkPermission } = usePermissions();

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        id="createBrand"
        style="light-blue"
        text={t('brands.createBrand')}
        iconLeft={<BrandsIcon />}
        customClassnames={`${!checkPermission(PERMISSIONS.CREATE_BRAND) && 'hidden'}`}
      />
      {open && <BrandPopUp setOpen={setOpen} refetch={refetch} />}
    </>
  );
};
