import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import CheckAuth from '../../components/auth/CheckAuth';
import RequireAuth from '../../components/auth/RequireAuth';
import { ContentConfirm } from '../../components/campaigns/content/ContentConfirm';
import { SingleCampaignContentTab } from '../../components/campaigns/SingleTabs/ContentTab';
import { SingleCampaignDatesTab } from '../../components/campaigns/SingleTabs/DatesTab';
import { SingleCampaignGeneralTab } from '../../components/campaigns/SingleTabs/GeneralTab';
import { SingleCampaignInvoicesTab } from '../../components/campaigns/SingleTabs/InvoicesTab';
import { SingleCampaignMetricsInfluencersTab } from '../../components/campaigns/SingleTabs/MetricsInfluencersTab';
import { SingleCampaignMetricsTab } from '../../components/campaigns/SingleTabs/MetricsReportTab';
import { SingleCampaignResourcesTab } from '../../components/campaigns/SingleTabs/ResourcesTab';
import { SingleCampaignRecurrentResourcesTab } from '../../components/campaigns/SingleTabs/ResourcesTabRecurrent';
import { SingleCampaignSettingsInfluencerTab } from '../../components/campaigns/SingleTabs/SettingsInfluencerTab';
import { SingleCampaignRecurrentSettingsInfluencerTab } from '../../components/campaigns/SingleTabs/SettingsInfluencerTabRecurrent';
import { SingleCampaignSettingsTab } from '../../components/campaigns/SingleTabs/SettingsTab';
import { SingleCampaignSettingsRecurrentTab } from '../../components/campaigns/SingleTabs/SettingsTabRecurrent';
import { NotFound } from '../../components/notfound/notFound';
import { usePermissions } from '../../hooks/usePermissions';
import DashboardLayout from '../../layouts/DashboardLayout';
import Brands from '../../views/Brands';
import Calendar from '../../views/Calendar';
import Campaigns from '../../views/Campaigns';
import CampaignsRecurrent from '../../views/CampaignsRecurrent';
import Dashboard from '../../views/Dashbord';
import Influencers from '../../views/Influencers';
import InfluentiaUsers from '../../views/InfluentiaUsers';
import Invoices from '../../views/Invoices';
import Profile from '../../views/Profile';
import ProfileBrandAccess from '../../views/ProfileBrandAccess';
import ProfileNotifications from '../../views/ProfileNotifications';
import { SingleBrand } from '../../views/SingleBrand';
import { SingleBrandCampaigns } from '../../views/SingleBrandCampaigns';
import SingleCampaign from '../../views/SingleCampaign';
import SingleCampaignRecurrent from '../../views/SingleCampaignRecurrent';
import { SingleInfluencer } from '../../views/SingleInfluencer';
import { SingleInfluencerCampaigns } from '../../views/SingleInfluencerCampaigns';
import { SingleInfluentia } from '../../views/SingleInfluentiaUser';
import { PERMISSIONS } from '../constants';
import { ROUTES } from './config';

const AppRouter = () => {
  const {
    isInfluentia,
    checkPermission,
    isInfluentiaExternal,
    isBrand,
    isInfluencer,
    userLoaded,
  } = usePermissions();
  const getSingleCampaignTabRoutes = (url: any) => {
    return url.replace('/campaign/:campaignId/', '');
  };
  const getSingleCampaignRecurrentTabRoutes = (url: any) => {
    return url.replace('/campaign-recurrent/:campaignId/', '');
  };
  // const location = useLocation();
  // useEffect(() => {
  //   // Reset States according to location
  // }, [location]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<CheckAuth />} />
        <Route
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.CAMPAIGNS} element={<Campaigns />} />
          <Route path={ROUTES.CALENDAR} element={<Calendar />} />
          <Route path={ROUTES.INVOICES} element={<Invoices />} />
          <Route path={ROUTES.PROFILE} element={<Profile />} />
          <Route
            path={ROUTES.PROFILE_NOTIFICATIONS}
            element={<ProfileNotifications />}
          />
          <Route
            path={ROUTES.PROFILE_ORG_AND_ACCESS}
            element={<ProfileBrandAccess />}
          />
          <Route
            path={ROUTES.SINGLE_CAMPAIGN_GLOBAL}
            element={<SingleCampaign />}
          >
            {checkPermission(PERMISSIONS.READ_CAMPAIGN) && (
              <Route
                path={getSingleCampaignTabRoutes(
                  ROUTES.SINGLE_CAMPAIGN_GENERAL,
                )}
                element={<SingleCampaignGeneralTab />}
              />
            )}
            {checkPermission(PERMISSIONS.READ_CAMPAIGN) && (
              <Route
                path={getSingleCampaignTabRoutes(
                  ROUTES.SINGLE_CAMPAIGN_CONTENT,
                )}
                element={<SingleCampaignContentTab />}
              />
            )}
            {checkPermission(PERMISSIONS.READ_RESOURCES) && (
              <Route
                path={getSingleCampaignTabRoutes(
                  ROUTES.SINGLE_CAMPAIGN_RESOURCES,
                )}
                element={<SingleCampaignResourcesTab />}
              />
            )}
            {checkPermission(PERMISSIONS.READ_DATES) && (
              <Route
                path={getSingleCampaignTabRoutes(ROUTES.SINGLE_CAMPAIGN_DATES)}
                element={<SingleCampaignDatesTab />}
              />
            )}
            {checkPermission(PERMISSIONS.READ_METRIC) && (
              <Route
                path={getSingleCampaignTabRoutes(
                  ROUTES.SINGLE_CAMPAIGN_METRICS_INFLUENCERS,
                )}
                element={<SingleCampaignMetricsInfluencersTab />}
              />
            )}
            {checkPermission(PERMISSIONS.READ_METRIC) && (
              <Route
                path={getSingleCampaignTabRoutes(
                  ROUTES.SINGLE_CAMPAIGN_METRICS_REPORT,
                )}
                element={<SingleCampaignMetricsTab />}
              />
            )}
            {checkPermission(PERMISSIONS.READ_INVOICES) && (
              <Route
                path={getSingleCampaignTabRoutes(
                  ROUTES.SINGLE_CAMPAIGN_INVOICES,
                )}
                element={<SingleCampaignInvoicesTab />}
              />
            )}
            {checkPermission(PERMISSIONS.READ_CAMPAIGN_USER) && (
              <Route
                path={getSingleCampaignTabRoutes(
                  ROUTES.SINGLE_CAMPAIGN_SETTINGS_INFLUENCER,
                )}
                element={<SingleCampaignSettingsInfluencerTab />}
              />
            )}
            {checkPermission(PERMISSIONS.READ_CAMPAIGN_SETTINGS) && (
              <Route
                path={getSingleCampaignTabRoutes(
                  ROUTES.SINGLE_CAMPAIGN_SETTINGS,
                )}
                element={<SingleCampaignSettingsTab />}
              />
            )}
          </Route>

          {((userLoaded && isInfluentia()) || !userLoaded) && (
            <>
              {/* Recurrent Campaigns */}
              {checkPermission(PERMISSIONS.READ_CAMPAIGN_RECURRENT) && (
                <>
                  <Route
                    path={ROUTES.CAMPAIGNS_RECURRENT}
                    element={<CampaignsRecurrent />}
                  />
                  <Route
                    path={ROUTES.SINGLE_CAMPAIGN_RECURRENT_GLOBAL}
                    element={<SingleCampaignRecurrent />}
                  >
                    <Route
                      path={getSingleCampaignRecurrentTabRoutes(
                        ROUTES.SINGLE_CAMPAIGN_RESOURCES_RECURRENT,
                      )}
                      element={<SingleCampaignRecurrentResourcesTab />}
                    />
                    <Route
                      path={getSingleCampaignRecurrentTabRoutes(
                        ROUTES.SINGLE_CAMPAIGN_RECURRENT_SETTINGS_INFLUENCER,
                      )}
                      element={<SingleCampaignRecurrentSettingsInfluencerTab />}
                    />
                    <Route
                      path={getSingleCampaignRecurrentTabRoutes(
                        ROUTES.SINGLE_CAMPAIGN_RECURRENT_SETTINGS,
                      )}
                      element={<SingleCampaignSettingsRecurrentTab />}
                    />
                  </Route>
                </>
              )}
              {/* Brands */}
              {checkPermission(PERMISSIONS.READ_BRAND) && (
                <>
                  <Route path={ROUTES.BRANDS} element={<Brands />} />
                  <Route path={ROUTES.SINGLE_BRAND} element={<SingleBrand />} />
                  <Route
                    path={ROUTES.SINGLE_BRAND_CAMPAIGNS}
                    element={<SingleBrandCampaigns />}
                  />
                </>
              )}
              {/* Influencers */}
              {checkPermission(PERMISSIONS.READ_INFLUENCER) && (
                <>
                  <Route path={ROUTES.INFLUENCERS} element={<Influencers />} />
                  <Route
                    path={ROUTES.SINGLE_INFLUENCER}
                    element={<SingleInfluencer />}
                  />
                  <Route
                    path={ROUTES.SINGLE_INFLUENCER_CAMPAIGNS}
                    element={<SingleInfluencerCampaigns />}
                  />
                </>
              )}
              {/* Influentia Users */}
              {checkPermission(PERMISSIONS.READ_USER) &&
                !isInfluentiaExternal() && (
                  <>
                    <Route
                      path={ROUTES.INFLUENTIA_USERS}
                      element={<InfluentiaUsers />}
                    />
                    <Route
                      path={ROUTES.SINGLE_INFLUENTIA_USER}
                      element={<SingleInfluentia />}
                    />
                  </>
                )}
            </>
          )}
          <Route path="*" element={<NotFound />} />
          <Route path={ROUTES.CONFIRM_CONTENT} element={<ContentConfirm />} />
        </Route>
      </>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
